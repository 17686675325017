window.site = (window.site || {});

/**
 * Utilities for PostCard component
 * @class PostCard
 * @static
 */
site.PostCard = (function PostCard() {

  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  var currentPostID = null;

  /**
   * Initializes the class.
   * @public
   */
  var init = function () {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    checkCommentsHeight();

    // Delegate submit trigger for post-actions
    $(document).on('click', '.post-comment[data-trigger-submit]', function(e) {
      e.preventDefault();
      var simLink = $(this).attr('data-trigger-submit');
      $('.comment-form [data-trigger-submit="' + simLink + '"]').trigger('click');
      checkCommentsHeight();
    });

    // Toggle to show/hide comments block
    $(document).on('click', '.action-comment', function(e) {
      e.preventDefault();
      $(this).parents('.newsfeed-post').toggleClass('is-commenting');
      $(this).parents('.newsfeed-post').find('textarea').focus();

      // window.SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;

      // const recognition = new SpeechRecognition();
      // recognition.interimResults = true;
      // recognition.lang = 'en-US';

      // let p = document.createElement('p');
      // const words = $(this).parents('.newsfeed-post').find('.form-control').eq(0)[0];
      // // words.appendChild(p);

      // recognition.addEventListener('result', e => {
      //   const transcript = Array.from(e.results)
      //     .map(result => result[0])
      //     .map(result => result.transcript)
      //     .join('');

      //     
      //     words.value = transcript;

      //     
      //     if (e.results[0].isFinal) {
      //       p = document.createElement('p');
      //       words.value = transcript;
      //       words.style.border = "2px solid red";
      //     }
      // });

      // recognition.addEventListener('end', recognition.start);

      // recognition.start();
    });

    // Send Comment Form AJAX
    $(document).on('submit', '.comment-form', function(e) {
        e.preventDefault();

        var $form = $(this);
        var $scope = $form.parents('.newsfeed-post-card');
        var data = $form.serialize();
        var $button = $form.find('[data-trigger-submit]');
        var userEmail = $button.data('user-email');


        if (!$form.parsley().validate() || $button.hasClass('is-loading')) {
                    return false;
        }

        $button.addClass('is-loading');

        

        $.ajax({
            method: 'POST',
            url: data.action,
            data: data,
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        })
        .success(function(data) {
            
            $button.removeClass('is-loading');

            // Clear out the text box
            $form.find('textarea[name="fields[comment]"]').val('');

            // Add the new comment html
            var $comment_html = $(data.html).find('.comment-single');

            
            // Are we replying to a comment, or on the top-level? Determines where to append
            if (data.comment && data.comment.parentId) {
                var $replies_container = $('#comment-' + data.comment.parentId + ' .comments-list', $scope);

                // Hide the reply form
                $form.hide();

                // Check for existing container for other replies
                if ($replies_container.length == 0) {
                                                        $('#comment-' + data.comment.parentId).append('<ul class="comments-list"/>');
                }

                                
                $('#comment-' + data.comment.parentId + ' > .comments-list', $scope).append($comment_html);

                toastr.success('Your reply has been posted.');

              window.dataLayer = window.dataLayer || [];
              dataLayer.push({
                'event': 'comment',
                'email' : $(this).data('user-email')
              });
            } else {
              
                $('.comments-list:first', $scope).append($comment_html);

                $('.comment-single .comment-form').hide();

                $('.comments-list:first', $scope).parents('.comments-container').css('max-height', $('.comments-list:first', $scope).parents('.comments-container').find('.comments-list').height() + 70);

                toastr.success('Your comment has been posted.');

                window.dataLayer = window.dataLayer || [];
                dataLayer.push({
                  'event': 'comment',
                });

                return false;
            }

            refreshCommentsCount($scope);


            var $el = $('[data-read-more="' + $button.attr('data-trigger-submit') + '"]');
            $el.toggleClass('is-active');

            var $btnEl = $('a[data-read-more="' + $button.attr('data-trigger-submit') + '"]');

            if ($btnEl.hasClass('is-active')) {
              $btnEl.find('span').text($btnEl.attr('data-active-text'));
            } else {
              $btnEl.find('span').text($btnEl.attr('data-disabled-text'));
            }
        })
        .error(function(data) {
                    });
    });

    // Toggle to show/hide replies
    $(document).on('click', 'a.comment-toggle', function(e) {
      e.preventDefault();
      $(this).parents('.comment-single:first').find('.comments-list:first').toggle();

      var $el = $('div[data-read-more="' + $(this).attr('data-parentid') + '"]:visible');

      $el.css('max-height', $el.find('> .comments-list').height() + 70);
    });

    // Toggle to show/hide reply form
    $(document).on('click', 'a.comment-reply', function(e) {
      e.preventDefault();
      $(this).parents('.comment-single:first').find('.comment-form:first').toggle();

      var $el = $('div[data-read-more="' + $(this).attr('data-parentid') + '"]:visible');

      $el.css('max-height', $el.find('> .comments-list').height() + 70);
    });

    // Handle flagging
    $(document).on('click', 'a.comment-flag', function(e) {
        e.preventDefault();

        $.ajax({
            url: $(this).attr('href'),
        })
        .success(function(data) {
            
            // update label
            $(e.target).parent().parent().find('.comment-flag').replaceWith('<span class="static-label comment-flag"><span class="glyphicon glyphicon-flag"></span>Flagged as inappropriate</span>');
        })
        .error(function(data) {
                    });
    });

    // Handle deleting
    $(document).on('click', 'a.comment-delete', function(e) {
        e.preventDefault();

        var $link = $(this);

        var msg = document.createElement("div");
        msg.innerHTML =  "<p>Are you sure you want to delete your comment?</p>";

        swal({
          title: "Are you sure?",
          content: msg,
          icon: "warning",
          buttons: ["No", "Yes"]
        })
        .then((userAccept) => {
          if (userAccept) {

            var $scope = $link.parents('.newsfeed-post-card');
            var commentReference = $link.parents('.dropdown').attr('data-options-box');
            var $commentReference = $('#comment-' + commentReference + '').slideUp(355, function () {
              $commentReference.remove();
              $.ajax({
                url: $link.attr('href'),
              })
              .success(function(data) {
                                    refreshCommentsCount($scope);
                  toastr.success('Your comment has been deleted.');

                window.dataLayer = window.dataLayer || [];
                dataLayer.push({
                  'event': 'formSubmission',
                  'category': 'deleted-comment',
                  'action': 'send',
                  'label': 'form'
                });
              })
              .error(function(data) {
                                });
            });
          }
        });
    });

    // Handle editing
    $(document).on('click', 'a.comment-edit', function(e) {
        e.preventDefault();

        // Simply hides text and shows form
        $(this).parents('.comment-text').find('.comment-content').hide();
        $(this).parents('.comment-text').find('.edit-comment-form').show();
    });

    $(document).on('submit', '.edit-comment-form', function(e) {
        e.preventDefault();

        var data = $(this).serialize();
        var $self = $(this);

        $.ajax({
            method: 'POST',
            url: data.action,
            data: data,
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        })
        .success(function(data) {
                        

            var comment = data.comment;

            // Update the comment text
            var comment_text = comment.replace(/\n/g, '<br />');
            $self.parents('.comment-text').find('.comment-content').html('<p>'+comment_text+'</p>');

            // Hide/show form and content
            $self.parents('.comment-text').find('.comment-content').show();
            $self.parents('.comment-text').find('.edit-comment-form').hide();
        })
        .error(function(data) {
                    });
    });

    // Like/UnLike post
    $(document).on('click', '.post-like', function(e) {
      e.preventDefault();
      e.stopPropagation();
      
      var $action = $(this);

      $.get($action.attr('href'), function(data) {
          /*optional stuff to do after success */
          $action.toggleClass('is-liked');
          $action.find('i').toggleClass('icon-like icon-heart');

          if ( $action.hasClass('is-liked') ) {
            $('.likes-count', $action).text(parseInt($('.likes-count', $action).text()) + 1);
          } else {
            $('.likes-count', $action).text(parseInt($('.likes-count', $action).text()) - 1);
          }
      });
    });

    // Comment options falafel btn
    $(document).on('click', function(e) {
      $('[data-options-box]').removeClass('is-active');
    });
    $(document).on('click', '.comment-options-btn, .view-list-action', function(e) {
            e.stopPropagation();
      $('[data-options-box]').removeClass('is-active');
      $('[data-options-box="' + $(this).attr('data-options-box') + '"]').addClass('is-active');
    });

    
    // Read More Action
    $(document).on('click', '[data-read-more]', function(e) {

      if ($(this).hasClass('do-not-trigger')) {
        return true;
      }

      e.preventDefault();
      
      var $el = $('[data-read-more="' + $(this).attr('data-read-more') + '"]');
      $el.toggleClass('is-active');

      
      var $divEl = $('div[data-read-more="' + $(this).attr('data-read-more') + '"]');

      var singleComments = $divEl.find('.comment-single');
      var commentsMaxHeight = $divEl.parents().eq(1).find('.lined-title').outerHeight();

      $.each(singleComments, function(index){
        if(index == 3) return false;

                commentsMaxHeight = commentsMaxHeight + $(this).outerHeight(true);
      });


      if($el.hasClass('is-active')) {
        $divEl.css('max-height', '515')
      }else {
                // $divEl.parents().eq(1).find('.comments-container').css('max-height', commentsMaxHeight);
        $("html, body").animate({ scrollTop: $divEl.offset().top - 160 }, 700);
      }


      
      var $btnEl = $('a[data-read-more="' + $(this).attr('data-read-more') + '"]');

      if ($btnEl.hasClass('is-active')) {
        $btnEl.find('span').text($btnEl.attr('data-active-text'));
      } else {
        $btnEl.find('span').text($btnEl.attr('data-disabled-text'));
      }
/*
      setTimeout(function() {
        $('.comment-content').css('background-color', '#25cad8');
        $('.comments-list .comments-list .comment-content').css('background-color', '#DDDDDD');
      }, 100);*/
    });


    $.contextMenu({
      selector: '[data-context-menu="posts-owner"]',
      trigger: 'left',
      callback: function(key, options) {

        if (key === 'edit') {
          editPostCard();
        }

        if (key === 'delete') {
          deletePostCard();
        }

      },
      items: {
        "edit": {name: "Edit", icon: function($element, key, item){ return 'icon-edit'; }},
        "delete": {name: "Delete", icon: function($element, key, item){ return 'icon-delete'; }},
        // "share": {name: "Share", icon: function($element, key, item){ return 'icon-share'; }},
        "quit": {name: "Close", icon: function($element, key, item){ return 'icon-close'; }}
      }
    });

    $.contextMenu({
      selector: '[data-context-menu="posts"]',
      trigger: 'left',
      callback: function(key, options) {

        if (key === 'edit') {
          editPostCard();
        }

        if (key === 'delete') {
          deletePostCard();
        }

        if (key === 'report') {
          reportPostCard();
        }

      },
      items: {
        "report": {name: "Report", icon: function($element, key, item){ return 'icon-flag'; }},
        // "share": {name: "Share", icon: function($element, key, item){ return 'icon-share'; }},
        "quit": {name: "Close", icon: function($element, key, item){ return 'icon-close'; }}
      }
    });

    $.contextMenu({
      selector: '[data-context-menu="articles-owner"]',
      trigger: 'left',
      callback: function(key, options) {

        if (key === 'edit') {
          //           var postSlug = $('article[data-post-id="'+currentPostID+'"]').attr('data-post-slug');
          window.location.href = "/dashboard/article/create?article=" + postSlug
        }

        if (key === 'delete') {
          deletePostCard();
        }

      },
      items: {
        "edit": {name: "Edit", icon: function($element, key, item){ return 'icon-edit'; }},
        "delete": {name: "Delete", icon: function($element, key, item){ return 'icon-delete'; }},
        // "shareArticles": {name: "Share", icon: function($element, key, item){ return 'icon-share'; }},
        "quit": {name: "Close", icon: function($element, key, item){ return 'icon-close'; }}
      }
    });

    $.contextMenu({
      selector: '[data-context-menu="articles"]',
      trigger: 'left',
      callback: function(key, options) {

        if (key === 'edit') {
          editPostCard();
        }

        if (key === 'delete') {
          deletePostCard();
        }

        if (key === 'report') {
          reportPostCard();
        }

        if (key === 'save') {
          pinPost();
        }

      },
      items: {
        "report": {name: "Report", icon: function($element, key, item){ return 'icon-flag'; }},
        // "share": {name: "Share", icon: function($element, key, item){ return 'icon-share'; }},
        "save": {name: "Pin to dashboard", icon: function($element, key, item){ return 'icon-save'; }},
        "quit": {name: "Close", icon: function($element, key, item){ return 'icon-close'; }}
      }
    });

    $.contextMenu({
      selector: '[data-context-menu="users"]',
      trigger: 'left',
      callback: function(key, options) {

        if (key === 'report') {
          reportUserCard();
        }

      },
      items: {
        "report": {name: "Report", icon: function($element, key, item){ return 'icon-flag'; }},
        "quit": {name: "Close", icon: function($element, key, item){ return 'icon-close'; }}
      }
    });

    $.contextMenu({
      selector: '[data-context-menu="users-profile"]',
      trigger: 'left',
      callback: function(key, options) {

        if (key === 'report') {
          reportUserProfile();
        }

      },
      items: {
        "report": {name: "Report", icon: function($element, key, item){ return 'icon-flag'; }},
        "quit": {name: "Close", icon: function($element, key, item){ return 'icon-close'; }}
      }
    });

    $.contextMenu({
      selector: '[data-context-menu="users-profile-connected"]',
      trigger: 'left',
      callback: function(key, options) {

        if (key === 'report') {
          reportUserProfile();
        }
        if (key === 'remove') {
          removeConnectionLink();
        }

      },
      items: {
        "report": {name: "Report", icon: function($element, key, item){ return 'icon-flag'; }},
        "remove": {name: "Remove Connection", icon: function($element, key, item){ return 'icon-chain-broken'; }},
        "quit": {name: "Close", icon: function($element, key, item){ return 'icon-close'; }}
      }
    });

    $.contextMenu({
      selector: '[data-context-menu="shared-articles-owner"]',
      trigger: 'left',
      callback: function(key, options) {

        if (key === 'remove') {
          removePostCard();
        }

      },
      items: {
        "remove": {name: "Remove", icon: function($element, key, item){ return 'icon-eye-blocked'; }},
        "quit": {name: "Close", icon: function($element, key, item){ return 'icon-close'; }}
      }
    });

    $.contextMenu({
      selector: '[data-context-menu="shared-articles"]',
      trigger: 'left',
      callback: function(key, options) {

          if (key === 'report') {
              reportUserCard();
          }

      },
      items: {
        "report": {name: "Report", icon: function($element, key, item){ return 'icon-flag'; }},
        "quit": {name: "Close", icon: function($element, key, item){ return 'icon-close'; }}
      }
    });

    $.contextMenu({
      selector: '[data-context-menu="network-cards-connection"]',
      trigger: 'left',
      callback: function(key, options) {

          if (key === 'remove') {
              removeConnectionLink();
          }

      },
      items: {
        "remove": {name: "Remove connection", icon: function($element, key, item){ return 'icon-chain-broken'; }},
        "quit": {name: "Close", icon: function($element, key, item){ return 'icon-close'; }}
      }
    });

    $('body').on('click', '[data-context-menu]', function(e){
      currentPostID = $(this).attr('data-post-id');
    });

    return true;

  };

  var refreshCommentsCount = function ($scope) {

    var commentsCount = $('.comments-list .comment-content', $scope).length;

    
    if (commentsCount) {
        $scope.addClass('has-comments');
        $('.comments-count', $scope).text(commentsCount);
    } else {
        $scope.removeClass('has-comments');
        $('.comments-count', $scope).text(0);
    }

    $.each( $('.comments-list .comment-single'), function(index, val) {
      var $scope = $(this);
      var repliesCount = $('.comment-single', $scope).length;

      if (repliesCount) {
          $('.nb-comment-replies', $scope).text(repliesCount);
      } else {
          $scope.removeClass('has-comments');
          $('.nb-comment-replies', $scope).text(0);
      }
    });
  };

  var editPostCard = function () {

    if (!currentPostID) {
      return;
    }

    var type = $('article[data-post-id="'+currentPostID+'"]').attr('data-post-type');
    var postSlug = $('article[data-post-id="'+currentPostID+'"]').attr('data-post-slug');
    var postHasImage = $('article[data-post-id="'+currentPostID+'"]').find('.post-embed-card').length;

    var selectedTextarea = $('article[data-post-id="'+currentPostID+'"]:visible').find('.post-content-edit textarea');

    var textareaHTML = $.parseHTML(selectedTextarea.val());
    var cleanedString = '';

    $.each(textareaHTML, function(index, val) {
      cleanedString += ($(this).text());

          });

            
    selectedTextarea.val(cleanedString);

    
    characterCount(selectedTextarea);

    selectedTextarea.on('keyup keydown', function(){
      characterCount($(this));
    });


    if (type === 'newsfeedArticles') {
      location.href = "/dashboard/article/create?article=" + postSlug;
      return;
    }

        
    $('article[data-post-id="'+currentPostID+'"]').find('.post-content').hide();

    $('article[data-post-id="'+currentPostID+'"]').find('.post-content-edit .js-cancel-edit').on('click', function(e) {
            e.preventDefault();
      $('article[data-post-id="'+currentPostID+'"]').find('.post-content-edit').hide();
      $('article[data-post-id="'+currentPostID+'"]').find('.post-image-remover').hide()
      $('article[data-post-id="'+currentPostID+'"]').find('.post-content:not(.post-content-edit)').show();
    });


    if (type !== 'newsfeedArticles' && postHasImage) {
      
      $('article[data-post-id="'+currentPostID+'"]').find('.post-content-edit-image').show();



      $('article[data-post-id="'+currentPostID+'"]').find('.post-image-remover').show();

      $('article[data-post-id="'+currentPostID+'"]').find('.post-image-remover').on('click', function (e) {
        e.preventDefault();

        if ($(this).hasClass('remove')) {
          $(this).parents('.post-embed-card').find('img').hide();
          $('article[data-post-id="'+currentPostID+'"]').find('.post-content-edit-image form input[name="imageShouldBeRemove"]').val(1);

          $(this).removeClass('remove');
          $(this).text($(this).attr('data-unremove-text'));
        } else {
          $(this).parents('.post-embed-card').find('img').show();
          $('article[data-post-id="'+currentPostID+'"]').find('.post-content-edit-image form input[name="imageShouldBeRemove"]').val(0);

          $(this).addClass('remove');
          $(this).text($(this).attr('data-remove-text'));
        }
      });


      //
      /**
       * Submit Image form for modifications
       */
      $('article[data-post-id="'+currentPostID+'"]').find('.post-content-edit-image form').on('submit', function(e) {

        $('.alert').remove();

        // Disable button
        e.preventDefault();

        var $form = $(this);
        var $formSubmit = $form.find('[type="submit"]');
        var $ajaxZone = $('[data-ajax-result="' + $form.parents('[data-content-creator-box]').attr('data-content-creator-box') + '"]');

        if ( ! $form.parsley().validate() ) {
          return false;
        }

        // Send form data through an ajax post
        var data = new FormData($form[0]);
            data.append('site_csrf', site.App.config('csrf'));

        var url = '/feed/savePost';

        
        $formSubmit.prop('disabled', true);
        $formSubmit.addClass('loading');
        $formSubmit.text('Saving...');

        var request = $.ajax({
          url: url,
          type: 'POST',
          data: data,
          cache: false,
          contentType: false,
          processData: false,
        });

        // Request done
        request.done(function(response) {

          var $old = $('article[data-post-id="'+currentPostID+'"]');

          // Add the new post to FEED
          var $newPostHtml = $(response).find('article[data-post-id="'+currentPostID+'"]');

          $newPostHtml.insertAfter($old);

          $old.remove();

          toastr.success('Your post has been updated.');

          window.dataLayer = window.dataLayer || [];

          // Datalayer - Form Homepage & Depositaires
          dataLayer.push({
            'event': 'formSubmission',
            'category': 'edit-image-post',
            'action': 'send',
            'label': 'form'
          });

        });

        // Request failed
        request.fail(function(jqXHR, textStatus) {
          alert('Erreur serveur: ' + textStatus);
        });

      });
      //
    } else {
      $('article[data-post-id="'+currentPostID+'"]').find('.post-content-edit:not(.post-content-edit-image)').show();
      $('article[data-post-id="'+currentPostID+'"]').find('.post-content-edit:not(.post-content-edit-image) textarea').trigger('keyup');

      $(document).on('submit', 'article[data-post-id="'+currentPostID+'"] .post-content-edit:not(.post-content-edit-image) form', function(e) {
        event.preventDefault();


        var $form = $(this);
        var $formSubmit = $form.find('[type="submit"]');
        var $ajaxZone = $('[data-ajax-result="' + $form.parents('[data-content-creator-box]').attr('data-content-creator-box') + '"]');

        if ( ! $form.parsley().validate() ) {
          return false;
        }

        // Send form data through an ajax post
        var data = new FormData($form[0]);
        data.append('site_csrf', site.App.config('csrf'));

        
        var url = '/feed/saveEntry';

        $formSubmit.prop('disabled', true);
        $formSubmit.addClass('loading');
        $formSubmit.text('Saving...');

        $.ajax({
          type: 'POST',
          url: url,
          data: data,
          cache: false,
          contentType: false,
          processData: false
        })
        .success(function(data) {

            var $old = $('article[data-post-id="'+currentPostID+'"]');

            // Add the new post to FEED
            var $newPostHtml = $(data).find('article[data-post-id="'+currentPostID+'"]');

            $newPostHtml.insertAfter($old);

            $old.remove();

            toastr.success('Your post has been updated.');

            window.dataLayer = window.dataLayer || [];
            dataLayer.push({
              'event': 'formSubmission',
              'category': 'edit-text-post',
              'action': 'send',
              'label': 'form'
            });
        })
        .error(function(data) {
                    });
      });
    }
  };

  var deletePostCard = function () {

    if (!currentPostID) {
      return;
    }

    var type = $('article[data-post-id="'+currentPostID+'"]').attr('data-post-type');
    var postSlug = $('article[data-post-id="'+currentPostID+'"]').attr('data-post-slug');

    swal({
      title: "Are you sure?",
      text: "Are you sure you want to delete your post?",
      icon: "warning",
      buttons: true,
      dangerMode: true
    })
    .then((willDelete) => {
      if (willDelete) {
        $.get('/actions/site_Feed/deleteUsersPost?id=' + currentPostID, function(data) {

          if ($('article[data-post-id="'+currentPostID+'"]').attr('data-post-redirect') === 'redirect') {
            toastr.success('Your article has been deleted.');
            window.dataLayer = window.dataLayer || [];
            dataLayer.push({
              'event': 'formSubmission',
              'category': 'delete-article',
              'action': 'send',
              'label': 'form'
            });
            location.href = "/dashboard";
            return;
          }

          $('article[data-post-id="'+currentPostID+'"]').fadeOut(355);
          toastr.success('Your post has been deleted.');

          window.dataLayer = window.dataLayer || [];
          dataLayer.push({
            'event': 'formSubmission',
            'category': 'delete-post',
            'action': 'send',
            'label': 'form'
          });

        });
      }
    });
  };

  var removePostCard = function () {

    if (!currentPostID) {
      return;
    }

    var type = $('article[data-post-id="'+currentPostID+'"]').attr('data-post-type');
    var postSlug = $('article[data-post-id="'+currentPostID+'"]').attr('data-post-slug');

    swal({
      title: "Are you sure?",
      text: "Are you sure you want to remove this article?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      buttons: ["No", "Yes"]
    })
    .then((willDelete) => {
      if (willDelete) {
        $.get('/actions/site_Feed/removeSharedPost?id=' + currentPostID, function(data) {

          $('article[data-post-id="'+currentPostID+'"]').fadeOut(355);
          toastr.success('Article has been removed and will no longer appear in your Discover feed.');

          window.dataLayer = window.dataLayer || [];
          dataLayer.push({
            'event': 'formSubmission',
            'category': 'delete-shared-post',
            'action': 'send',
            'label': 'form'
          });

        });
      }
    });
  };

  var reportPostCard = function () {

    if (!currentPostID) {
      return;
    }

    var type = $('article[data-post-id="'+currentPostID+'"]').attr('data-post-type');
    var postSlug = $('article[data-post-id="'+currentPostID+'"]').attr('data-post-slug');

      $.get('/actions/site_Feed/reportPost?id=' + currentPostID).success(function(data) {
          if(data.error){
              toastr.success('You have already reported this item!');

            window.dataLayer = window.dataLayer || [];
            dataLayer.push({
              'event': 'formSubmission',
              'category': 'already-reported-post',
              'action': 'send',
              'label': 'form'
            });
          }else {
              toastr.success('The item has been reported, thank you.');

            window.dataLayer = window.dataLayer || [];
            dataLayer.push({
              'event': 'formSubmission',
              'category': 'report-post',
              'action': 'send',
              'label': 'form'
            });
          }
      })
  };

  var reportUserCard = function () {

    if (!currentPostID) {
      return;
    }

    var type = $('article[data-post-id="'+currentPostID+'"]').attr('data-post-type');
    var postSlug = $('article[data-post-id="'+currentPostID+'"]').attr('data-post-slug');

    $.get('/actions/site_Feed/reportUser?id=' + currentPostID, function(data) {
        if(data.error){
            toastr.success('You have already reported this member!');

          window.dataLayer = window.dataLayer || [];
          dataLayer.push({
            'event': 'formSubmission',
            'category': 'already-reported-user-card',
            'action': 'send',
            'label': 'form'
          });
        }else {
            toastr.success('This member has been reported, thank you.');

          window.dataLayer = window.dataLayer || [];
          dataLayer.push({
            'event': 'formSubmission',
            'category': 'report-user-card',
            'action': 'send',
            'label': 'form'
          });
        }
    });

  };

  var reportUserProfile = function () {

    if (!currentPostID) {
      return;
    }

    var type = $('article[data-post-id="'+currentPostID+'"]').attr('data-post-type');
    var postSlug = $('article[data-post-id="'+currentPostID+'"]').attr('data-post-slug');


      swal({
          title: "Are you sure?",
          text: "Are you sure you want to report this user? If so, please provide us a reason why",
          icon: "warning",
          buttons: true,
          dangerMode: true,
          content: {
              element: "input",
              attributes: {
                  name: "fields[reportUserReasons]",
                  placeholder: "Type your reason",
                  type: "text",
              },
          }
      })
      .then((value) => {
      if (value) {

                  $.get('/actions/site_Feed/reportUserProfile?id=' + currentPostID + '&reason=' + value, function(data) {
              if(data.error){
                  toastr.success('You have already reported this member!');

                window.dataLayer = window.dataLayer || [];
                dataLayer.push({
                  'event': 'formSubmission',
                  'category': 'already-reported-user-profile',
                  'action': 'send',
                  'label': 'form'
                });
              }else {
                  toastr.success('This member has been reported, thank you.');

                window.dataLayer = window.dataLayer || [];
                dataLayer.push({
                  'event': 'formSubmission',
                  'category': 'report-user-profile',
                  'action': 'send',
                  'label': 'form'
                });
              }
          });
      }
      });

    var field = $('input[name="fields[reportUserReasons]"]');
    field.wrap('<div class="js-limit swal-input-limit textarea-holder"></div>');
    field.attr('maxlength', '280');
    $('.swal-input-limit').append('<span class="character-count">280</span>');
    field.on('keyup keydown', function(){
            characterCount($(this));
    });
  };

  var pinPost = function () {

    if (!currentPostID) {
      return;
    }

    var type = $('article[data-post-id="'+currentPostID+'"]').attr('data-post-type');
    var postSlug = $('article[data-post-id="'+currentPostID+'"]').attr('data-post-slug');

    $.get('/actions/site_User/addPostToSaved?id=' + currentPostID, function(data) {
      toastr.success('This article has been pinned to your dashboard. Visit your Dashboard to see all your pinned items.');

      window.dataLayer = window.dataLayer || [];
      dataLayer.push({
        'event': 'formSubmission',
        'category': 'pinned-article',
        'action': 'send',
        'label': 'form'
      });
    });

  };

  var removeConnectionLink = function() {

    if (!currentPostID) {
      return;
    }

    $.get('/actions/site_User/removeUser?id=' + currentPostID, function(data) {
      toastr.success('The connection has been removed.');

      window.dataLayer = window.dataLayer || [];
      dataLayer.push({
        'event': 'formSubmission',
        'category': 'remove-connection',
        'action': 'send',
        'label': 'form'
      });
      location.reload();
    });
  };

  var characterCount = function(textarea) {
    var reg = /(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?/;
    var textValue = textarea.val();

    var postCommentBtn = $('[action-post-comment]');

    if (textarea.val().length >= 30) {
      postCommentBtn.removeClass('is-disabled');
    } else {
      postCommentBtn.addClass('is-disabled');
    }

    postCommentBtn.prop('disabled', textarea.val().length <= 30);

    if(reg.test(textValue)){

      var array = textarea.val().match(reg);

      textarea.closest('.js-limit').find('.character-count').html(280 - ((textarea.val().length - array[0].length) + 23) + ' <small>(min. 30 characters)</small>');
    }else {
      textarea.closest('.js-limit').find('.character-count').html(280 - textarea.val().length + ' <small>(min. 30 characters)</small>');
    }

  };

  var checkCommentsHeight = function() {
    $.each($('.app-index div[data-read-more] >.comments-list'), function() {

      
      var singleComments = $(this).find('.comment-single');

      if(singleComments.length > 3 && !$(this).parents().eq(1).find('.comments-container').hasClass('is-active')) {
        //Add .lined-title to the calculation because of it's white bg
        var commentsMaxHeight = $(this).parents().eq(1).find('.lined-title').outerHeight();

        $.each(singleComments, function(index){
          if(index == 3) return false;

                    commentsMaxHeight = commentsMaxHeight + $(this).outerHeight(true);
        });

        $(this).parents().eq(1).find('.lined-title').css('display', 'flex');
        // $(this).parents().eq(1).find('.comments-container').css('max-height', commentsMaxHeight);
      } else {
        $(this).parents().eq(1).find('.comments-container').addClass('is-active');
      }
    });
  };

  // Expose public methods & properties
  return {
    init: init,
    checkCommentsHeight: checkCommentsHeight
  };

}());
