window.site = (window.site || {});

/**
 * Utilities for Filters component
 * @class Filters
 * @static
 */
site.Filters = (function Filters() {

  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  if (!$('.filters-cloud').length) {
    return false;
  }

  if (!$('#filter-bar').length) {
    return false;
  }

  var $filtersComponent = $('.filters-cloud');
  var $filterBar = $('#filter-bar');
  // var $filterBarBottom = $filterBar.outerHeight() + $('.top-nav').outerHeight();
  var $filterBarBottom = ($filterBar.offset().top) - $filterBar.outerHeight();
  var $addPostButton = $('.add-post');

  /**
   * Initializes the class.
   * @public
   */
  var init = function () {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    $addPostButton.css('display', 'none');

    // Init();
    
    $("a[href='#newsfeed-posts']").on('click', function(event) {

    $('.burger').toggleClass('is-active');
    $('.mobile-nav').toggleClass('is-active');

    if (this.hash !== "") {
      event.preventDefault();

      var hash = this.hash;

      $('html, body').animate({
        scrollTop: $(hash).offset().top
      }, 800, function(){

        window.location.hash = hash;
      });
      }
    });

    setTimeout(function() {
      $filterBarBottom = ($filterBar.offset().top) - $filterBar.outerHeight();
          }, 2777);

    // $(window).on('resize', function(event) {
    //   var $filterBarBottom = ($filterBar.offset().top) - $filterBar.outerHeight();
    //       // });

    $(window).on('scroll', function () {
      var top = $(window).scrollTop();

      
      if ($filterBarBottom > top) {
        // $filterBar.removeClass('affix');
        // $filtersComponent.removeClass('affix');
        // $addPostButton.css('display', 'none');
      } else {
        // $filterBar.addClass('affix');
        // $filtersComponent.addClass('affix');
        // $addPostButton.css('display', 'block');
      }
    });

    // $filtersComponent.slideUp();

    $('.toggle-filters').on('click', function(event) {
      event.preventDefault();

      $filterBar.toggleClass('is-filtering');

      if ($filterBar.hasClass('is-filtering')) {
        $filtersComponent.slideDown(300);
      } else {
        $filtersComponent.slideUp(300);
      }

    });

    $filtersComponent.on('click', '.tag', function(event) {
      event.preventDefault();

      var $scope = $filtersComponent,
        tagId = $(this).attr('data-tagId');

      $('.tag[data-tagId="' + tagId + '"]', $scope).toggleClass('is-active');

      if ($('.tag.is-active', $scope).length) {
        $('.active-tags', $scope).addClass('is-active');
      } else {
        $('.active-tags', $scope).removeClass('is-active');
      }

      $('#tag_' + tagId).click();
    });

    $('#filter-form').submit(function(e) {

      var checkedValues = $('.filters-tags :checkbox:checked').map(function() {
        return this.value;
      }).get();

      $(this).find('[name=tags]').val(checkedValues.join('_'));

      return true;
    });

    $filtersComponent.on('click', '.action-see-all', function(event) {
      event.preventDefault();

      $(this).toggleClass('is-active');

      if ($(this).hasClass('is-active')) {
        $('.innactive-tags').addClass('is-active');
        $(this).text($(this).attr('data-active-text'));
      } else {
        $('.innactive-tags').removeClass('is-active');
        $(this).text($(this).attr('data-innactive-text'));
      }
    });

    $filtersComponent.on('click', '.action-clear-all', function(event) {
      event.preventDefault();
      $('.tags-list.active-tags .tag.is-active').trigger('click');
    });

  };

  // Expose public methods & properties
  return {
    init: init
  };

}());
