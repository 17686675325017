window.site = (window.site || {});

/**
 * Init AjaxPagination related scripts
 * @class Site
 * @static
 */
site.AjaxPagination = (function AjaxPagination($) {

    'use strict';

    /**
     * jQuery elements
     * @private
     */

    /**
     * Has the class been initialized?
     * @private
     */
    var inited = false;
    var $pagniations = $('[data-ajax-pagination]');

    /**
     * Initializes the class.
     * @public
     */
    var init = function () {

        // Abort if already initialized
        if (inited) {
            return false;
        }

        inited = true;

        $.each( $pagniations, function(index, val) {

            var $paginator = $(this);
            var $ajaxZone = $('[data-ajax-paginate="' + $paginator.attr('data-ajax-pagination') + '"]');

            $paginator.on('click', 'a', function(event) {
                event.preventDefault();

                var $page = $(this);
                var $loader = '<div class="loader-container" style="width:  ' + $ajaxZone.outerWidth() + 'px; height: ' + $ajaxZone.outerHeight() + 'px;"><div class="loader"><svg class="circular" viewBox="25 25 50 50"><circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"/></svg></div></div>';

                $ajaxZone.empty().css('background', '#fffcfc').append($loader);
                $paginator.slideUp(355);
                $.ajax({
                    method: 'GET',
                    url: $page.attr('href')
                  })
                  .success(function(data) {
                    
                    var $refreshArticle = $(data).find('[data-ajax-paginate="' + $paginator.attr('data-ajax-pagination') + '"] .network-post-card');

                    
                    $ajaxZone.css('background', '#fff').empty().append($refreshArticle);

                    var $refreshPagination = $(data).find('[data-ajax-pagination="' + $paginator.attr('data-ajax-pagination') + '"] .content-pagination');

                    
                    $paginator.slideDown(355).empty().append($refreshPagination);
                  })
                  .error(function(data) {
                                        // isSbumitting = false;
                  });
            });

        });

        return true;

    };

    // Expose public methods & properties
    return {
        init: init,
    };

}(jQuery));
