window.site = (window.site || {});

/**
 * Utilities for FormLabel components
 * @class FormLabel
 * @static
 */
site.FormLabel = (function FormLabel() {

  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;
  var $inputs = $('form input, form textarea');

  /**
   * Initializes the class.
   * @public
   */
  var init = function () {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    // Init();
    $.each($inputs, function(index, val) {
      var $input = $(this);
      var $label = $('.label-helper[data-helper="'+$input.attr('name')+'"]');

      $label.text($input.attr('placeholder'));

      if ($input.val()) {
        $label.addClass('is-active');
      }

      $input.on('change', function(event) {
        event.preventDefault();
        $label.addClass('is-active');
      });
    });

    $('body').on('change', 'input[type="file"]', function() {
      var $parentInput = $(this).parents('.input-field');
      var path = $(this).val();
      var filename = path.replace(/^.*\\/, '');
      $parentInput.addClass('is-active').find('.input-delegate').text( filename );
      $parentInput.find('[action-upload-class]').addClass('is-disabled');
      // $(this).parents('.image-form').parsley().validate();
    });

    return true;

  };

  // Expose public methods & properties
  return {
    init: init
  };

}());
