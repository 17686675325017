window.site = (window.site || {});

/**
 * Main application class.
 * @class App
 * @static
 */
site.App = (function App() {

  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Application config defaults.
   * @private
   * @param config.env     Current server environment
   * @param config.csrf    Security token to submit with forms
   * @param config.locale  Current locale short code
   * @param config.device  Device detection based on browser signature
   * @param config.preview Page is shown through live preview mode
   * @param config.general Settings from general config
   */
  var config = {
    env: 'production',
    csrf: null,
    locale: 'en',
    device: 'desktop',
    preview: false,
    general: {},
    algolia: {}
  };

  /**
   * Initializes the class.
   * @public
   */
  var init = function(options) {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    // Store application settings
    options = (options || {});

    if (options.env) { config.env = options.env; }
    if (options.csrf) { config.csrf = options.csrf; }
    if (options.locale) { config.locale = options.locale; }
    if (options.device) { config.device = options.device; }
    if (options.preview) { config.preview = options.preview; }
    if (options.general) { config.general = options.general; }
    if (options.algolia) { config.algolia = options.algolia; }

    // Initialize child classes
    if (typeof site.Global === 'object') {site.Global.init();}
    if (typeof site.Track === 'object') {site.Track.init();}
    // if (typeof site.Search === 'object') {site.Search.init();}
    if (typeof site.SearchBar === 'object') {site.SearchBar.init();}
    if (typeof site.StripeAPI === 'object') {site.StripeAPI.init();}
    if (typeof site.PostCard === 'object') {site.PostCard.init();}
    if (typeof site.ContentCreatorBox === 'object') {site.ContentCreatorBox.init();}
    if (typeof site.Filters === 'object') {site.Filters.init();}
    if (typeof site.ProfileFilters === 'object') {site.ProfileFilters.init();}
    // if (typeof site.GeolocationServices === 'object') {site.GeolocationServices.init();}
    if (typeof site.Network === 'object') {site.Network.init();}
    if (typeof site.Dashboard === 'object') {site.Dashboard.init();}
    if (typeof site.Auth === 'object') {site.Auth.init();}
    if (typeof site.Article === 'object') {site.Article.init();}
    if (typeof site.Newsfeed === 'object') {site.Newsfeed.init();}
    if (typeof site.NewsCarousel === 'object') {site.NewsCarousel.init();}
    // if (typeof site.UploadedImagePreview === 'object') {site.UploadedImagePreview.init();}
    if (typeof site.SwitchToggleShow === 'object') {site.SwitchToggleShow.init();}
    if (typeof site.UploadImagePost === 'object') {site.UploadImagePost.init();}
    if (typeof site.FormLabel === 'object') {site.FormLabel.init();}
    if (typeof site.Profile === 'object') {site.Profile.init();}
    if (typeof site.MessagingApp === 'object') {site.MessagingApp.init();}
    if (typeof site.OverlayModal === 'object') {site.OverlayModal.init();}
    // if (typeof site.Demo === 'object') {site.Demo.init();}
    if (typeof site.CropperJS === 'object') {site.CropperJS.init();}
    if (typeof site.AjaxPagination === 'object') {site.AjaxPagination.init();}
    if (typeof site.LiveNotificationsCounter === 'object') {site.LiveNotificationsCounter.init();}
    if (typeof site.SystemNotifications === 'object') {site.SystemNotifications.init();}
    if (typeof site.Map === 'object') {site.Map.init();}
    if (typeof site.VideoPlayer === 'object') {site.VideoPlayer.init();}

    $('[data-temp-disable]').css('opacity', '.25');
    $(document).on('click', '[data-temp-disable]', function(event) {
      event.preventDefault();
      event.stopPropagation();
    });

    // Return success
    return true;

  };

  /**
   * Getter for application config.
   * @public
   */
  var getConfig = function(option) {
    return config[option] ? config[option] : false;
  };

  /**
   * Expose public methods & properties.
   */
  return {
    init: init,
    config: getConfig,
  };

}());
