window.site = (window.site || {});

site.Demo = (function Demo($) {

  var uploadedImage = false;

  function demoUpload() {
    var $uploadCrop = null;

    function readFile(input) {
      
      if (input.files && input.files[0] || !uploadedImage || uploadedImage != input.files[0]) {
        uploadedImage = input.files[0];

        var reader = new FileReader();

        reader.onload = function (e) {
          $('.live-cropper-uploader').addClass('ready');
          $uploadCrop.croppie('bind', {
            url: e.target.result,
            orientation: 1
          }).then(function(){
                      });
        }

        reader.readAsDataURL(input.files[0]);
      }else if(uploadedImage) {
                return false;
      }
      else {
        swal("Sorry - your browser doesn't support the FileReader API");
      }
    }

    var width = $('.input-banner').width();
    var height = $('.input-banner').height();
    var boundary = 100;

    
    $uploadCrop = $('#live-cropper-uploader').croppie({
      viewport: {
        width: width,
        height: height
      },
      boundary: {
        width: (width + boundary),
        height: (height + boundary)
      },
      enableExif: true
    });

    $('#live-cropper-uploader').css({'position':'relative', 'left':'-' + (boundary/2) + 'px'});

    $('#upload').on('change', function () {
      readFile(this);
      $('.live-cropper-uploader').css({'opacity': '1', 'z-index': '4'});
      $('.profile-avatar, .profile-box-stats').css('opacity', '0');
    });

    $('.upload-result').on('click', function (ev) {
      ev.preventDefault();

      var $button = $(this);

      $button.text('Saving...');

      // $('<p>').text('Start!!!').prependTo('.section-user-dashboard');

      $uploadCrop.croppie('result', {
        type: 'canvas',
        size: 'viewport',
        format: 'jpeg'
      }).then(function (blob) {

        
        // $('<p>').text(blob).prependTo('.section-user-dashboard');

        var $form = $('.cropper-form');

        var type = 'user';
        var action = '/site/user/updateBanner';
        var url = '/site/user/updateBanner';

        // if ($form.attr('data-type') === 'business') {
        //   type = 'business';
        //   action = '/site/user/updateBusinessBanner';
        //   url = '/site/user/updateBusinessBanner';
        // }

        // 
        // $('<p>').text(type + ' ' + url).prependTo('.section-user-dashboard');

        // Send form data through an ajax post
        // var data = new FormData($form[0]);
            // data.append('site_csrf', site.App.config('csrf'));
            // data.append('action', action);
            // data.append('fields[userBannerImage]', blob);

        var data = {
          site_csrf: site.App.config('csrf'),
          action: action,
          'fields[userBannerImage]': blob
        };

        // $('<p>').text(data).prependTo('.section-user-dashboard');
        
        var request = $.ajax({
          url: url,
          type: 'POST',
          data: data
        });

        request.always(function(response){
          // $('<p>').text(response).prependTo('.section-user-dashboard');
        });

        // Request done
        request.done(function(response) {

          // $('<p>').text(response).prependTo('.section-user-dashboard');

          if (response.success && response.success === true) {

            toastr.success('Your banner image has been updated.');
            $('.input-banner').css('background-image', 'url('+blob+')').css('background-size', 'cover').css('background-position', 'center');
            $('.live-cropper-uploader').css({'opacity': '0', 'z-index': '-1'});
            $('.profile-avatar, .profile-box-stats').css('opacity', '1');
            $button.text('Save');

            window.dataLayer = window.dataLayer || [];
            dataLayer.push({
              'event': 'formSubmission',
              'category': 'update-banner-image',
              'action': 'send',
              'label': 'form'
            });

            // Return success
            return true;

          } else if (response.errors) {

            $button.text('Save');
            toastr.success('There was an error trying to crop the image. Try again later.');

            window.dataLayer = window.dataLayer || [];
            dataLayer.push({
              'event': 'formSubmission',
              'category': 'error-image-banner-update',
              'action': 'send',
              'label': 'form'
            });

            // Return failure
            return false;

          }

        });

        // Request failed
        request.fail(function(jqXHR, textStatus) {
          $button.text('Save');

          alert(jqXHR);
          alert(textStatus);

          // alert('Erreur serveur: ' + textStatus);

          // $('<p>').text('In Error' + jqXHR).prependTo('.section-user-dashboard');
          // $('<p>').text('In Error' + textStatus).prependTo('.section-user-dashboard');
        });

      });
    });

    $('.upload-cancel').on('click', function(event) {
      event.preventDefault();
      $uploadCrop.croppie('bind');

      $('.live-cropper-uploader').css({'opacity': '0', 'z-index': '-1'});
      $('.profile-avatar, .profile-box-stats').css('opacity', '1');
      $('#upload').val('');

    });

  }

  function init() {
    // bindNavigation();
    // demoMain();
    // demoBasic();
    // demoVanilla();
    // demoResizer();
    demoUpload();
    // demoHidden();
  }

  return {
    init: init
  };
})(jQuery);
