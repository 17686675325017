window.site = (window.site || {});

/**
 * Init SearchBar related scripts
 * @class Site
 * @static
 */
site.SearchBar = (function SearchBar($) {

    'use strict';

    /**
     * jQuery elements
     * @private
     */

    /**
     * Has the class been initialized?
     * @private
     */
    var inited = false;
    var $searchBar = $('#search-wrapper');
    var $searchBtn = $('#search-btn');
    var $searchBtnMobile = $('#search-btn-mobile');
    var $searchInput = $('#search', $searchBar);
    var $searchSelect = $('.search-form-select', $searchBar);
    var $searchFilters = $('.filter-btn', $searchBar);
    var $searchClear = $('.search-clear', $searchBar);

    /**
     * Initializes the class.
     * @public
     */
    var init = function () {

        // Abort if already initialized
        if (inited || !$searchBar.length) {
            return false;
        }

        inited = true;

        
        // On Click filter btns
        // $searchFilters.on('click', function (e) {
        //   e.preventDefault();

        //   $searchFilters.removeClass('is-active');
        //   $(this).addClass('is-active');

        //   syncFilter();
        // });

        // On Click clear
        $searchClear.on('click', function(event) {
          event.preventDefault();
          $searchInput.val('').focus();
          $searchClear.hide();
        });

        $searchBtn.on('click', function(event) {
          $searchBar.toggleClass('is-visible');
        });

        $searchBtnMobile.on('click', function(event) {
          event.preventDefault();
          event.stopPropagation();
          $searchBar.toggleClass('is-visible');
        });

        // Sync a filter with select
        // syncFilter();

        return true;

    };

    // Sync a filter with select
    var syncFilter = function () {
      var filterBy = $('.filter-btn.is-active').attr('data-filter-by');
            $('option', $searchSelect).prop({
        selected: false
      });
      $('option[value="' + filterBy + '"]', $searchSelect).prop({
        selected: true
      });
      $searchSelect.trigger('change');
    };

    // Expose public methods & properties
    return {
        init: init,
    };

}(jQuery));
