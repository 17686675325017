window.site = (window.site || {});

/**
 * Utilities for SystemNotifications components
 * @class SystemNotifications
 * @static
 */
site.VideoPlayer = (function VideoPlayer() {

  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  var currentPostID = null;
  var $modal = $('.overlay-modal');

  var $startBtn = $('[action-video-start]'),
    $playBtn = $('[action-video-play]'),
    $volumeBtn = $('[action-video-volume]'),
    $volumeSlide = $('[action-volume-slide]'),
    $restartBtn = $('[action-video-restart]'),
    $closeFsBtn = $('[action-close-fs]'),
    videoTimer = null;

  var $tabBtn = $('[action-program-tab]');

  //STATE Checkers
  var down = false,
    fadeout = null;

  /**
   * Initializes the class.
   * @public
   */
  var init = function () {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;


    setInterval(function () {
      updateProgress();
    }, 1000);
    // OVERLAY INTERACTIONS

    $('a.post-test').click(function (e) {
      e.stopPropagation();

      var videoId = $(this).data('video-id');
      var $video = $('[target-video-id="' + videoId + '"]')[0];
      $video.pause();

      window.top.open($('a.post-test').attr('href'), 'Video Player');
    });

    $('a.post-file').click(function (e) {
      e.stopPropagation();

      var videoId = $(this).data('video-id');
      var $video = $('[target-video-id="' + videoId + '"]')[0];
      $video.pause();

      window.top.open($('a.post-test').attr('href'), 'Video Player');
    });

    $(document).on('click', '[action-video-start], .video-overlay', function (e) {
      e.preventDefault();
      goFullScreen();

      var videoId = $(this).data('video-id');
      var $video = $('[target-video-id="' + videoId + '"]')[0];

      e.stopPropagation();

      window.dataLayer = window.dataLayer || [];

      if ($(this).hasClass('is-cme-video')) {

        dataLayer.push({
          'event': 'startCourse',
          'courseName': '"' + $(this).data('video-title') + '"',
          'email': $(this).data('user-email')
        });
      } else {
        dataLayer.push({
          'event': 'videoView',
          'videoName': '"' + $(this).data('video-title') + '"',
          'email': $(this).data('user-email')
        });
      }


      if ($video.paused) {

        //Stop video if user is not logged in
        if ($('body').hasClass('loged-out') && $video.currentTime >= 60) {
          restrictVideo();
          return false;
        }

        $video.play();
        $(this).find('.icon-play').toggleClass('icon-play icon-pause');
        // $startBtn.fadeOut();
        hideOverlay();
      } else {


        if ($(e.target).is('div[class^=buffer-], ' +
          'button:not([action-video-play]),' +
          ' a.post-action, [action-video-volume], [action-video-restart]' +
          ' [data-auth-protection], .volume-slider, .volume-slider input')) {
          e.stopPropagation();
          return;
        }


        $video.pause();
        $(this).find('.icon-pause').toggleClass('icon-play icon-pause');
        $startBtn.fadeIn();

        // $('.share-post-cta[data-overlay]').click();

        hideOverlay();
      }
    });


    $restartBtn.on('click', function (e) {
      e.preventDefault();
      var videoId = $(this).data('video-id');

      var $video = $('[target-video-id="' + videoId + '"]')[0];
      $video.currentTime = 0;
    });

    $volumeBtn.on('click', function (e) {
      e.preventDefault();
      e.stopPropagation();

      var videoId = $(this).data('video-id');
      var $video = $('[target-video-id="' + videoId + '"]')[0];

      if ($(this).next('.volume-slider').hasClass('is-visible')) {
        $(this).next('.volume-slider').removeClass('is-visible').fadeOut();
        $(this).removeClass('is-visible');
      } else {
        $(this).next('.volume-slider').addClass('is-visible').fadeIn();
        $(this).addClass('is-visible');
      }
    });

    $volumeSlide.on('change', function (e) {

      e.preventDefault();
      e.stopPropagation();

      var videoId = $(this).data('video-id');
      var $video = $('[target-video-id="' + videoId + '"]')[0];

      $video.volume = $(this).val() / 100;
    });

    $closeFsBtn.on('click', function (e) {
      e.preventDefault();
      e.stopPropagation();
      closeFullScreen();
      var videoId = $(this).data('video-id');
      var $video = $('[target-video-id="' + videoId + '"]')[0];
      $video.pause();

      var esc = $.Event("keydown", { keyCode: 27 });
      $("body").trigger(esc);
    });

    $(document).stop(true, true).on('keyup keydown', function () {
      $(".video-overlay").mousemove();
    });

    $(".video-overlay").on("mousemove touchmove", function () {
      hideOverlay();
    });

    // SEEKER FUNCTIONS
    $('.buffer-holder').on('mouseover', function () {
      $('.buffer-holder, .video-controls').addClass('is-hovered');
    });

    $(document).on('keydown', function (e) {

      $('.buffer-holder, .video-controls').addClass('is-hovered');

      var $video = false;
      $.each($('[element-video-player]'), function (e) {
        if (!$(this)[0].paused) {
          $video = $(this)[0];
        }
      });

      //Stop video if user is not logged in
      if ($('body').hasClass('loged-out') && $video.currentTime >= 60) {
        $video.pause();
        restrictVideo();
      }

      if (e.keyCode === 37) { // left
        if (!$video) { return false; }
        $video.currentTime = $video.currentTime - 5;
        updateProgress();
      } else if (e.keyCode === 39) { // right
        if (!$video) { return false; }
        $video.currentTime = $video.currentTime + 5;
        updateProgress();
      }
    });

    $('.buffer-holder').on('mouseup', function (e) {

      var $video = false;
      $.each($('[element-video-player]'), function (e) {
        if (!$(this)[0].paused) {
          $video = $(this)[0];
        }
      });

      //Stop video if user is not logged in
      if ($('body').hasClass('loged-out') && $video.currentTime >= 60) {
        $video.pause();
      }
      e.stopPropagation();

      if (!$video) { return false; }

      var seekPercent = (e.pageX / $(this).width()) * 100;

      var newTime = ($video.duration * seekPercent) / 100;
      $video.currentTime = newTime;


      $('.buffer-buffering').width(0).css({ left: 0 });
      $('.buffer-holder, .video-controls').addClass('is-hovered');

      updateProgress();
    });

    $('.buffer-holder').on('mousedown', function () {
      down = true;
    });

    $(document).on('mouseup', function (e) {

      if (!$(e.target).is('div[class^=buffer-], button:not([action-video-play]), ' +
        'a.post-action, [action-video-volume], [data-auth-protection], .volume-slider, ' +
        '.volume-slider input, .groups-row')) {
        down = false;
      }
    });

    // THUMBNAIL PREVIEW FUNCTION
    $('.article-thumb.has-video').stop(true, true).on('mouseover', function () {
      if (!$('html').hasClass('fullscreen')) {
        $(this).addClass('is-active');
        let vd = $(this).find('video');
        if (vd.length) {
          let src = vd[0].children[0].getAttribute('data-src');
          vd[0].src = src;
          var isPlaying = vd[0].currentTime > 0 && !vd[0].paused && !vd[0].ended
            && vd[0].readyState > vd[0].HAVE_CURRENT_DATA;
          if (isPlaying) {
            vd[0].load();
          }
          getTeaser(vd[0], 0, 35);
        }
      }
    });

    $('.article-thumb.has-video').stop(true, true).on('mouseout', function () {
      if (!$('html').hasClass('fullscreen')) {
        $(this).removeClass('is-active');
        let vd = $(this).find('video');
        if (vd.length) {
          vd[0].removeAttribute('src');

          var isPlaying = vd[0].currentTime > 0 && !vd[0].paused && !vd[0].ended
            && vd[0].readyState > vd[0].HAVE_CURRENT_DATA;
          if (isPlaying) {
            vd[0].load();
          }
        }
        clearTimeout(videoTimer);
      }
    });

    setTimeout(function (e) {

      $(window, document, 'video').bind('webkitfullscreenchange mozfullscreenchange fullscreenchange', function (e) {
        var state = document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen;
        var event = state ? 'FullscreenOn' : 'FullscreenOff';

        var $video = false;
        var $parentContainer = false;

        $.each($('[element-video-player]'), function (e) {
          if (!$(this)[0].paused) {
            $video = $(this)[0];
            $parentContainer = $(this).parents().eq(0);
          }
        });

        if (event === 'FullscreenOn') {
          let src = $video.children[0].getAttribute('data-src');
          $video.src = src;
          $('html').addClass('fullscreen');
          // $startBtn.fadeOut();
          $parentContainer.addClass('is-playing is-played');


          $video.muted = false;

        } else {
          $('html').removeClass('fullscreen');
          // if(!$video){return false;}
          $video.removeAttribute('src');

          if ($parentContainer.length) {
            $parentContainer.removeClass('is-playing');
          }


          if ($('body').hasClass('loged-out')) {
            $('body').removeClass('has-overlay');
          }

          $video.muted = true;
          $video.pause();
          $startBtn.fadeIn();
        }
      });
    }, 100);

    $tabBtn.on('click', function (e) {
      e.preventDefault();
      switchTabs($(this).data('program-tab'));
      $tabBtn.removeClass('is-active');
      $(this).addClass('is-active');
    });

    return true;

  };

  var hideOverlay = function () {

    $('.video-overlay').addClass('is-visible');
    if (fadeout != null) {
      clearTimeout(fadeout);
    }
    fadeout = setTimeout(function () {
      $('.buffer-holder, .video-controls').removeClass('is-hovered');
      $('.video-overlay').removeClass('is-visible');
    }, 5000);

    return false;
  };

  var updateProgress = function () {

    /*if (down){ //Prevent update while seeking
      return false;
    }*/

    var $video = false;
    $.each($('[element-video-player]'), function (e) {
      if (!$(this)[0].paused) {
        $video = $(this)[0];
      }
    });

    if (!$video) { return false; }

    var duration = formatTime($video.duration);


    var currentTime = formatTime($video.currentTime);
    var progress = ($video.currentTime / $video.duration) * 100;
    $(".duration-current").text(currentTime);
    $(".duration-total").text(duration);

    $('.buffer-progress').width(progress + '%');

    for (var i = 0; i < $video.buffered.length; i++) {

      if ($video.buffered.end(i) < $video.currentTime) {
        continue;
      } else if ($video.buffered.start(i) > $video.currentTime) {
        continue;
      }

      var leftPos = ((($video.buffered.end(i) - $video.buffered.start(i)) / $video.duration) * 100);

      $('.buffer-buffering').width(leftPos + '%');
      $('.buffer-buffering').css({ left: (($video.buffered.start(i) / $video.duration) * 100) + '%' });

    }

    //Stop video if user is not logged in
    if ($('body').hasClass('loged-out') && $video.currentTime >= 60) {
      $video.pause();
      restrictVideo();
    }
  };

  var formatTime = function (seconds) {
    var minutes = Math.floor(seconds / 60);
    minutes = (minutes >= 10) ? minutes : '0' + minutes;
    seconds = Math.floor(seconds % 60);
    seconds = (seconds >= 10) ? seconds : '0' + seconds;
    return minutes + ':' + seconds;
  };

  var goFullScreen = function () {
    var el = document.documentElement,
      requestFs = el.requestFullScreen
        || el.webkitRequestFullScreen
        || el.mozRequestFullScreen
        || el.msRequestFullscreen;

    // if (!requestFs) {
    //   alert('not a request');
    //   // alert('This is not a fullScreen');
    //   // window.top.open(window.location.href, 'Video Player');
    //   return true;
    // }

    if (screenfull.enabled) {
      screenfull.request();
    } else if (!document.fullscreenElement &&    // alternative standard method
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement) {  // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen();
      }

      // requestFs.call(el);
    }

  };

  var closeFullScreen = function () {

    if (screenfull.enabled) {
      screenfull.exit();
    }

    $startBtn.fadeIn();
  };

  var restrictVideo = function () {
    var msg = document.createElement("div");
    msg.innerHTML = "<p>You must be a registered member to be able to view the complete video.</p> <a data-track data-action=\"click\" data-category=\"login\" data-label=\"overlay\" href='/auth/login' class='modal-links'>Log In</a> <br> <p>Not a member? Register FREE now!</p>";

    swal({
      title: "You must be logged in",
      content: msg,
      icon: "/assets/images/onco-logo.svg",
      buttons: ["No thanks", "Sign Up"]
    })
      .then((userAccept) => {
        if (userAccept) {
          window.dataLayer = window.dataLayer || [];

          // Datalayer - Form Homepage & Depositaires
          dataLayer.push({
            'event': 'click',
            'category': 'sign-up',
            'action': 'click',
            'label': 'overlay'
          });

          location.href = "/auth/signup";
        }
      });

    return false;
  };

  var getTeaser = function (videoPlayer, startTime, endTime) {

    videoPlayer.currentTime = startTime; //not sure if player seeks to seconds or milliseconds
    videoPlayer.play();

    //call function to stop player after given intervall
    var stopVideoAfter = (endTime - startTime) * 1000;  //* 1000, because Timer is in ms

    videoTimer = setTimeout(function () {
      videoPlayer.load();
    }, stopVideoAfter);
  };

  var switchTabs = function (tab) {
    $('[data-program-content]').removeClass('is-active');
    $('[data-program-content="' + tab + '"]').addClass('is-active');
  };

  // Expose public methods & properties
  return {
    init: init
  };

}());
