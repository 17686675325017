const SDK_KEY = "hBxOGgmPSo17Hee9KVDJoNentTLKJN0BkDAV";
const SDK_SECRET = "LBjQ8Nhpv9YBbWckbqnAJTXZcIt0ecVHFN7p";

class Zoom {
    meeting(meetingConfig) {
        ZoomMtg.checkSystemRequirements()
        ZoomMtg.preLoadWasm();
        ZoomMtg.prepareJssdk();
        ZoomMtg.init({
            leaveUrl: meetingConfig.leaveUrl,
            webEndpoint: meetingConfig.webEndpoint,
            disableCORP: !window.crossOriginIsolated, // default true
            // disablePreview: false, // default false
            success: function (res) {
                ZoomMtg.i18n.load(meetingConfig.lang);
                ZoomMtg.i18n.reload(meetingConfig.lang);
                let zmmtgroot = document.querySelector("#zmmtg-root");
                let zmmtgrootcontent = document.querySelector("#zmmtgroot-content");
                if (zmmtgroot) {
                    zmmtgrootcontent.appendChild(zmmtgroot)
                }
                ZoomMtg.join({
                    meetingNumber: meetingConfig.meetingNumber,
                    userName: meetingConfig.userName,
                    signature: meetingConfig.signature,
                    sdkKey: SDK_KEY,
                    userEmail: meetingConfig.userEmail,
                    passWord: meetingConfig.passCode,
                    success: function (res) {
                        // ZoomMtg.getAttendeeslist({});
                        // ZoomMtg.getCurrentUser({
                        //     success: function (res) {
                        //     },
                        // });
                    },
                    error: function (res) {
                        console.log(res);
                    },
                });
            },
            error: function (res) {
                console.log(res);
            },
        });

        ZoomMtg.inMeetingServiceListener('onUserJoin', function (data) {
            console.log('inMeetingServiceListener onUserJoin', data);
        });

        ZoomMtg.inMeetingServiceListener('onUserLeave', function (data) {
            console.log('inMeetingServiceListener onUserLeave', data);
        });

        ZoomMtg.inMeetingServiceListener('onUserIsInWaitingRoom', function (data) {
            console.log('inMeetingServiceListener onUserIsInWaitingRoom', data);
        });

        ZoomMtg.inMeetingServiceListener('onMeetingStatus', function (data) {
            console.log('inMeetingServiceListener onMeetingStatus', data);
        });
    }
    generateSDKSignature(meetingNumber, passCode, userName, userEmail) {
        let self = this;
        let lang = 'en-US';
        meetingNumber = meetingNumber.replace(/ /g, '')
        ZoomMtg.generateSDKSignature({
            meetingNumber: meetingNumber,
            sdkKey: SDK_KEY,
            sdkSecret: SDK_SECRET,
            role: 0,
            success: function (res) {
                self.meeting({
                    leaveUrl: '/index.php',
                    webEndpoint: null,
                    lang: lang,
                    meetingNumber: meetingNumber,
                    userName: userName,
                    signature: res.result,
                    userEmail: userEmail,
                    passCode: passCode,
                })
            },
        });
    }
}
