window.site = (window.site || {});

/**
 * Utilities for UploadImagePost form component
 * @class UploadImagePost
 * @static
 */
site.UploadImagePost = (function UploadImagePost($) {

  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * jQuery elements.
   * @private
   */
  var $form = $('[data-content-creator-box] form.image-form');

  /**
   * Initializes the class.
   * @public
   */
  var init = function() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    if ($form.length) {

      initForm();
    }

    return true;

  };

  /**
   * Initialize job form.
   * @private
   */
  var initForm = function() {

    
    var $error   = $('[data-alert-error]', $form);
    var $success = $('[data-alert-success]', $form);
    var $button  = $('[data-form-button]', $form);
    var $textarea  = $('textarea#content', $form);

    /**
     * Submit event
     */
    $form.on('submit', function(e) {

      $('.alert').remove();

      // Disable button
      e.preventDefault();

      var isMobile = site.App.config('device') === 'mobile';
      var $formSubmit = $form.find('[type="submit"]');
      var $ajaxZone = $('[data-ajax-result="' + $form.parents('[data-content-creator-box]').attr('data-content-creator-box') + '"]');

      
      if (isMobile) {
        $ajaxZone = $('[data-ajax-result="' + $form.parents('[data-content-creator-box]').attr('data-content-creator-box') + '-mobile"]');
      }

      if ( ! $form.parsley().validate() ) {
        return false;
      }

      // Send form data through an ajax post
      var data = new FormData($form[0]);
          data.append('site_csrf', site.App.config('csrf'));

      var url = '/feed/savePost';

      
      $formSubmit.prop('disabled', true);
      $formSubmit.addClass('loading');
      $ajaxZone.prepend($(`<div class="loading-effect" >
            <div class="sk-folding-cube">
              <div class="sk-cube1 sk-cube"></div>
              <div class="sk-cube2 sk-cube"></div>
              <div class="sk-cube4 sk-cube"></div>
              <div class="sk-cube3 sk-cube"></div>
            </div>
          </div>`));

      var request = $.ajax({
        url: url,
        type: 'POST',
        data: data,
        cache: false,
        contentType: false,
        processData: false,
      });

      // Request done
      request.done(function(response) {

        // $('<p>').text(response).prependTo('#newsfeed-posts');

        // alert(response);

        // Clear out the text box
        $form[0].reset();

        $form.find('[action-upload-class]').removeClass('is-disabled');
        $form.find('[action-post-comment]').addClass('is-disabled').prop('disabled', true);
        $form.find('.input-delegate').text('');

        // Add the new post to FEED
        var $newPostHtml = $(response).find('[data-ajax-result="' + $form.parents('[data-content-creator-box]').attr('data-content-creator-box') + '"] .newsfeed-post-card:first-child');

        
        $ajaxZone.prepend($newPostHtml);
        $formSubmit.prop('disabled', false);
        $ajaxZone.find('.loading-effect').remove();
        $ajaxZone.find('[data-empty-post-notification]').remove();
        // isSubmitting = false;

        toastr.success('You rock, thanks for posting! Your post has been shared.');
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({
          'event': 'formSubmission',
          'category': 'uploaded-image-post',
          'action': 'send',
          'label': 'form'
        });

      });

      // Request failed
      request.fail(function(jqXHR, textStatus) {
        alert('Erreur serveur: ' + textStatus);
      });

    });

  };

  // Expose public methods & properties
  return {
    init: init
  };

}(jQuery));
