window.site = (window.site || {});

/**
 * Utilities for MessagingApp components
 * @class MessagingApp
 * @static
 */
site.MessagingApp = (function MessagingApp() {

  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;
  var loaderFaker = null;
  var $ajaxMessage = $('.app-message-ajax-wrapper');

  /**
   * Initializes the class.
   * @public
   */
  var init = function () {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    if (!$('#privateMessagingSendForm').length) {
        return;
    }

    // Init();
    $.each( $('#privateMessagingSendForm select'), function(index, val) {
      $(this).select2({
        placeholder: $(this).attr('placeholder'),
        // maximumSelectionLength: 1
      });
    });

    // Messagin App
    $(document).on('click', '.action-message, [data-action-message]', function(e) {
      e.preventDefault();

      var recipientName = $(this).attr('data-recipient-name');
      var recipientID = $(this).attr('data-recipient-id');
      $('[data-recipient-id]', $('.popup-container')).val(recipientID);
      $('[data-recipient]', $('.popup-container')).text(recipientName);

      if($(this).data('business')) {
        $('#privateMessagingSendForm').prepend('<input type="hidden" name="business" value="1">');
      }

      $('body').addClass('is-active');
      $('.popup-overlay').addClass('is-active');
      $('.popup-container').addClass('is-active');
    });

    // Popup message app
    $('.popup-overlay, .close-btn').on('click', function(event) {
      event.preventDefault();

      $('#privateMessagingSendForm')[0].reset();
      $('#privateMessagingSendForm').find('.site-btn').removeClass('is-active');

      $('.loading-bar', $('#privateMessagingSendForm')).css('width', '0');
      clearInterval(loaderFaker);


      $('body').removeClass('is-active');
      $('.popup-overlay').removeClass('is-active');
      $('.popup-container').removeClass('is-active');
    });

    $(document).on('click', '#privateMessagingSendForm [type="submit"]', function(e) {
      e.preventDefault();



      var $action = $(this);
      var $form = $action.parents('form');
      var userName = $form.parents('.content-creator-forms').find('[data-recipient]').text();

      if ( ! $form.parsley({
          classHandler: function (el) {
              return el.$element.parent();
          },
          errorsContainer: function(el) {
              return el.$element.closest('.input-label');
          }
      }).validate() ) {
          return false;
      }

      if ($action.hasClass('is-active') || $action.hasClass('is-loading')) {
        return;
      }

      $action.addClass('is-loading').prop('disabled', true);

      var width = 1;
      loaderFaker = setInterval(load, 10);

      function load() {
        if (width >= 100) {
          clearInterval(loaderFaker);
        } else {
          width++;
          $('.loading-bar', $action).css('width', width +  '%');
        }
      }

      $.ajax({
        type: 'post',
        url: $('[name="action"]', $form).val(),
        data: $form.serializeArray(),
        success: function(response) {

          
          if (!response.success) {
            alert(response.error);
            // Re-Enable send button
            $action.removeClass('is-loading').prop('disabled', false);
            return false;
          }

          // Re-Enable send button
          $action.removeClass('is-loading').prop('disabled', false);
          $('.loading-bar', $action).css('width', '100%');

          setTimeout(function () {
            clearInterval(loaderFaker);
            $action.toggleClass('is-active');

            $form[0].reset();

            if($form[0]['number']) {
                if($form[0]['business']) {
                    var url = "/_app/pages/dashboard/business/ajax-message.twig?number=" + $form[0]['number'].value;
                }else {
                    var url = "/_app/pages/dashboard/message/ajax-message.twig?number=" + $form[0]['number'].value;
                }

                                $ajaxMessage.load(url);
            }

            // toastr.success('Your message has been sent to ' + userName);
            toastr.success('Your message has been sent');
            window.dataLayer = window.dataLayer || [];
            dataLayer.push({
              'event': 'formSubmission',
              'category': 'sent-message',
              'action': 'send',
              'label': 'form'
            });

            setTimeout(function () {
              $('.popup-overlay').trigger('click');
            }, 1500);
          }, 500);

          return true;
        }
      });
    });

    return true;

  };

  // Expose public methods & properties
  return {
    init: init
  };

}());
