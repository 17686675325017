window.site = (window.site || {});

/**
 * Utilities for Stripe
 * @class Stripe
 * @static
 */
site.StripeAPI = (function StripeAPI() {

    'use strict';

    if (!$('#payment-form').length && !$('#card-form').length) {
      return false;
    }

    // if (typeof Stripe == 'undefined') var Stripe = false;

    /**
     * General API variables and check if script is init
     * @private
     */
    var inited = false;

    if(typeof Stripe !== 'undefined'){
        // var stripe = Stripe('pk_live_n0aQx5lrOyxxeVAradkzZMuD');
        var stripe = Stripe('pk_test_wOaj0VMgjt4iwnl3S4am90Eo');
                /**
         * Define Stripe Elements DOM globally
         * @private
         */
        var elements = stripe.elements(),
            elementsClasses = {base: 'stripe-input-holder'},
            elementsStyles = {base: {fontSize: '16px', color: '#1D2B38'}},
            cardNumber = elements.create('cardNumber', {style: elementsStyles, classes: elementsClasses}),
            cardExpiry = elements.create('cardExpiry', {style: elementsStyles, classes: elementsClasses}),
            cardCVC = elements.create('cardCvc', {style: elementsStyles, classes: elementsClasses});
    }

    /**
     * Global variables
     * @private
     */

    var $form = document.getElementById('payment-form'),
        $cardForm = document.getElementById('card-form'),
        $billingToggle = $('.js-billing-toggle'),
        $billingForm = $('.js-billing'),
        $couponToggle = $('#couponCheckbox'),
        $couponField = $('#coupon'),
        $couponHolder = $('.coupon-holder'),
        $updateCoupon = $('[action-update-coupon]');

    var $planDetailsToggle = $('.js-plan-toggle');

    var $planGold = $('.js-link-gold'),
        $planPlatinum = $('.js-link-platinum'),
        $platPrice = $('.js-price-plat'),
        $goldPrice = $('.js-price-gold'),
        $monthText = $('.js-text-monthly'),
        $yearText = $('.js-text-year'),
        $planToggle = $('input[name=plan]'),
        $savedPrice = $('.saved-price');

    var $taxSelector = $('[action-province-tax]'),
        $planTypeSelector = $('input[name=plan_type]'),
        $countrySelector = $('[action-country-select]'),
        $subtotalText = $('[action-subtotal-text]'),
        $totalText = $('[action-total-text]'),
        $taxText = $('[action-tax-text]'),
        $priceText = $('[action-price-text]');

    var allowSubmit = true;


    /**
     * Initializes the class.
     * @public
     */
    var init = function () {

        // Abort if already initialized
        if (inited) {
            return false;
        }

        inited = true;

        
        if(typeof Stripe !== 'undefined'){
                      mountForm();
        }

        if ($form) {

            var onLoadCountry = $countrySelector.find(':selected').val();

            if(onLoadCountry == 'Canada') {
                              updatePrice($taxSelector.find(':selected').data('tax-name'), $taxSelector.find(':selected').data('tax'), $planTypeSelector.is(':checked') ? 'platinum' : 'gold', $planToggle.is(':checked') ? 'yearly' : 'monthly');
            }else {
                                updatePrice('', 0, $planTypeSelector.is(':checked') ? 'platinum' : 'gold', $planToggle.is(':checked') ? 'yearly' : 'monthly');
            }

            $form.addEventListener('submit', function (e) {
                e.preventDefault();
                e.stopPropagation();

                if(allowSubmit) {
                    submitPayment();
                    allowSubmit = false;
                }
            });

            toggleCoupon();
        }

        if ($cardForm) {
            $cardForm.addEventListener('submit', function (e) {
                e.preventDefault();
                updateCard();
            });
        }

        $billingToggle.on('click', function () {
            toggleBilling();
        });

        $planDetailsToggle.on('click', function (e) {
            togglePlans($(this));
        });


        $planToggle.on('click', function(e){
          updatePlan($(this).is(':checked'));

          var tax = $taxSelector.find(':selected').data('tax');
          var taxName = $taxSelector.find(':selected').data('tax-name');
          var plan = $planTypeSelector.is(':checked') ? 'platinum' : 'gold';
          var cycle = $(this).is(':checked') ? 'yearly' : 'monthly';

          if($couponHolder.is(":visible")) {
            updateTotalWithCoupon(taxName, tax, plan, cycle)
          }else {
            updatePrice(taxName, tax, plan, cycle);
          }
        });

        $planTypeSelector.on('click', function(e) {
          var tax = $taxSelector.find(':selected').data('tax');
          var taxName = $taxSelector.find(':selected').data('tax-name');
          var plan = $(this).is(':checked') ? 'platinum' : 'gold';
          var cycle = $planToggle.is(':checked') ? 'yearly' : 'monthly';

          if($couponHolder.is(":visible")) {
            updateTotalWithCoupon(taxName, tax, plan, cycle)
          }else {
            updatePrice(taxName, tax, plan, cycle);
          }
        });

        $taxSelector.on('change', function(e) {
          var tax = $(this).find(':selected').data('tax');
          var taxName = $(this).find(':selected').data('tax-name');
          var plan = $planTypeSelector.is(':checked') ? 'platinum' : 'gold';
          var cycle = $planToggle.is(':checked') ? 'yearly' : 'monthly';

          if($couponHolder.is(":visible")) {
            updateTotalWithCoupon(taxName, tax, plan, cycle)
          }else {
            updatePrice(taxName, tax, plan, cycle);
          }
        });

        $countrySelector.on('change', function(e) {
          var plan = $planTypeSelector.is(':checked') ? 'platinum' : 'gold';
          var cycle = $planToggle.is(':checked') ? 'yearly' : 'monthly';
          var $updatedTax = $("[action-province-tax]").val($("[action-province-tax] option:not([disabled])").first().val());

          var countryName = $(this).find(':selected').val();

          if(countryName == 'Canada') {
            var tax = $updatedTax.find(':selected').data('tax');
            var taxName = $updatedTax.find(':selected').data('tax-name');

            if($couponHolder.is(":visible")) {
              updateTotalWithCoupon(taxName, tax, plan, cycle)
            }else {
              updatePrice(taxName, tax, plan, cycle);
            }
          } else {
            if($couponHolder.is(":visible")) {
              updateTotalWithCoupon('', 0, plan, cycle)
            }else {
              updatePrice('', 0, plan, cycle);
            }
          }
        });

        $couponToggle.on('change click', function(event) {
          toggleCoupon();
        });

        $updateCoupon.on('click',function(e) {
          e.preventDefault();
          var tax = $taxSelector.find(':selected').data('tax');
          var taxName = $taxSelector.find(':selected').data('tax-name');
          var plan = $planTypeSelector.is(':checked') ? 'platinum' : 'gold';
          var cycle = $planToggle.is(':checked') ? 'yearly' : 'monthly';

          if($('#payment-form input#coupon').val()) {
              updateTotalWithCoupon(taxName, tax, plan, cycle);
          }
        });

        return true;

    };

    /**
     * Create DOM with Stripe Elements
     */
    var mountForm = function () {
        //Mount Card number field
        if (document.getElementById('cardNumber-element') != null) {
            cardNumber.mount('#cardNumber-element');
        }

        //Mount Card expiry field
        if (document.getElementById('cardExpiry-element')) {
            cardExpiry.mount('#cardExpiry-element');
        }

        //Mount Card CVC field
        if (document.getElementById('cardCVC-element')) {
            cardCVC.mount('#cardCVC-element');
        }
    };

    /****
     * Handles source creation and checks for form submit
     */
    var submitPayment = function () {
        //Retrieve customer's information from form
        var province = document.getElementById("province");
        var country = document.getElementById("country");
        var ownerInfo = {
          owner: {
              name: document.getElementById("cardholder").value,
              email: document.getElementById("email").value,
              address: {
                  city: document.getElementById("city").value,
                  country: country.options[country.selectedIndex].value,
                  line1: document.getElementById("address1").value,
                  line2: document.getElementById("address2").value,
                  state: province.options[province.selectedIndex].value,
              }
          }
        };

        //Create Stripe Source for backend payment
        stripe.createSource(cardNumber, ownerInfo).then(function (result) {
            if (result.error) {
                // Inform the user if there was an error
                var errorElement = document.getElementById('card-errors');
                errorElement.textContent = result.error.message;
                allowSubmit = true;
            } else {
                // Send the source to your server
                document.getElementById("submit-payment").innerHTML = 'Submitting Payment...';
                stripeSourceHandler(result.source);
            }
        });
    };
    /****
     * Handles source creation and checks for form submit
     */
    var updateCard = function () {
        var card_name = document.getElementById('cardholder').value;

        //Create Stripe Source for backend payment
        stripe.createSource(cardNumber, {owner: {name: card_name}}).then(function (result) {
            if (result.error) {
                // Inform the user if there was an error
                var errorElement = document.getElementById('card-errors');
                errorElement.textContent = result.error.message;
            } else {
                // Send the source to your server
                stripeSourceHandler(result.source);
            }
        });
    };

    /***
     * Appends a source field to retrieve in the backend and submits form
     * @param source
     */
    var stripeSourceHandler = function (source) {

        //Set Source Token
        var hiddenSource = document.createElement('input');
        hiddenSource.setAttribute('type', 'hidden');
        hiddenSource.setAttribute('name', 'stripeSource');
        hiddenSource.setAttribute('value', source.id);

        if($form) {
            $form.appendChild(hiddenSource);
            $form.submit();
        }

        if($cardForm) {
            $cardForm.appendChild(hiddenSource);
            $cardForm.submit();
        }
    };

    /**
     * Toggle Billing Address form
     */
    var toggleBilling = function () {
        $billingForm.toggleClass('is-active');

        if ($billingForm.hasClass('is-active')) {
            $billingForm.slideDown(300);
        } else {
            $billingForm.slideUp(300);
        }
    };

    /**
     * SlideUp/SlideDown for payment details
     */
    var togglePlans = function(button) {
      button.siblings('.js-plans-container').toggleClass('is-active');
      button.toggleClass('is-active');

      if (button.siblings('.js-plans-container').hasClass('is-active')) {
        button.siblings('.js-plans-container').slideDown(500);
      } else {
        button.siblings('.js-plans-container').slideUp(500);
      }
    };

    /**
     * Change URL and price of plan when toggling billing box
     */

    var updatePlan = function(state) {
      var billType = 'monthly';
      var goldPrice = 49;
      var platPrice = 99;
      var monthText = 'Monthly';
      var yearText = 'Annually';

      
      if(state) {
        billType = 'yearly';
        goldPrice = 499;
        platPrice = 999;
        monthText = 'Annually';
      }

      $planGold.attr('href', '/payment?billing='+ billType +'&plan=gold');
      $planPlatinum.attr('href', '/payment?billing='+ billType +'&plan=platinum');

      $savedPrice.toggleClass('is-visible');


      $goldPrice.prop('Counter', parseInt($goldPrice.eq(0).text())).animate({
          Counter: goldPrice
      }, {
          duration: 1000,
          easing: 'swing',
          step: function(now) {
            $(this).text(Math.ceil(now));
          }
      });

      $platPrice.prop('Counter', parseInt($platPrice.eq(0).text())).animate({
          Counter: platPrice
      }, {
        duration: 1000,
          easing: 'swing',
          step: function(now) {
          $(this).text(Math.ceil(now));
          }
      });

      $monthText.text(monthText);
    };

    /**
     * Update price for payment
     */
    var updatePrice = function(taxName, tax, plan, cycle, discount) {
      var price = 0;
      var discount = discount || 0;

      if(plan == 'gold' && cycle == 'monthly') {
        price = 49 - (discount * 49);
      }else if(plan == 'gold' && cycle == 'yearly') {
        price = 399 - (discount * 399);
      }else if(plan == 'platinum' && cycle =='monthly') {
        price = 99 - (discount * 99);
      }else if (plan =='platinum' && cycle == 'yearly') {
        price = 999 - (discount * 999);
      }

      var taxPrice = Math.ceil((price * (tax / 100)*100)) / 100;
      var total = Math.ceil((price + taxPrice) * 100) / 100;

                  
      $priceText.prop('Counter', parseInt($priceText.eq(0).text())).animate({
          Counter: price
      }, {
          duration: 1000,
          easing: 'swing',
          step: function(now) {
            var amount = Math.ceil(now*1000) / 1000;
            $(this).text(amount.toFixed(2));
          }
        });

      $subtotalText.prop('Counter', parseInt($subtotalText.eq(0).text())).animate({
          Counter: taxPrice
      }, {
          duration: 1000,
          easing: 'swing',
          step: function(now) {
            var amount = Math.ceil(now*1000) / 1000;
            $(this).text(amount.toFixed(2));
          }
        });

      $totalText.prop('Counter', parseInt($totalText.eq(0).text())).animate({
          Counter: total
      }, {
          duration: 1000,
          easing: 'swing',
          step: function(now) {
            var amount = Math.ceil(now*1000) / 1000;
            $(this).text(amount.toFixed(2));
          }
        });

      $taxText.text((taxName.length > 0 ? taxName + ' ' : '') + tax);

      return {taxTotal: taxPrice.toFixed(2), totalPrice: total.toFixed(2)};
    };

    var toggleCoupon = function () {

      if ($couponToggle.is(':checked')) {
        $couponHolder.fadeIn(355);
        $couponField.prop('disabled', false);
      } else {
        $couponHolder.fadeOut(355);
        $couponField.prop('disabled', true);

        var tax = $taxSelector.find(':selected').data('tax');
        var taxName = $taxSelector.find(':selected').data('tax-name');
        var plan = $planTypeSelector.is(':checked') ? 'platinum' : 'gold';
        var cycle = $planToggle.is(':checked') ? 'yearly' : 'monthly';

        updatePrice(taxName, tax, plan, cycle);
      }
    };

    var updateTotalWithCoupon = function(taxName, tax, plan, cycle) {
        var data = $('#payment-form input#coupon').serialize() + '&site_csrf=' + site.App.config('csrf');

        $.ajax({
            method: 'POST',
            url: '/payment/couponDiscount',
            data: data,
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        })
        .success(function(data){

          if(data > 0) {
              updatePrice(taxName,tax,plan,cycle, (data / 100));
          }

          $('.coupon-error').fadeOut(355);
        })
        .error (function(data) {
          $('.coupon-error').fadeIn(355);
        })
    };

    // Expose public methods & properties
    return {
        init: init
    };

}());
