window.site = (window.site || {});

/**
 * Utilities for ProfileFilters component
 * @class ProfileFilters
 * @static
 */
site.ProfileFilters = (function ProfileFilters() {

  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;
  var $scope = $('.app-profile');
  var $profileViewFilter = $('.profile-filters');

  /**
   * Initializes the class.
   * @public
   */
  var init = function () {

    // Abort if already initialized
    if (inited || !$scope.length) {
      return false;
    }

    inited = true;

    // Init();

    $('.newsfeed-view', $scope).fadeOut();
    $('.newsfeed-view.is-active', $scope).fadeIn();

    var baseUrl = location.protocol+'//'+location.host+location.pathname;
    
    if(location.hash) {
      switchTabs(baseUrl)
    }

    $profileViewFilter.on('click', '.filter-nav a', function(e) {
      e.preventDefault();
      switchTabs(baseUrl, $(this));
    });

  };

  var switchTabs = function(baseUrl, button) {

    $.each($('div[data-read-more] >.comments-list'), function() {

      if($(this).height() > 515) {
        $(this).parents().eq(1).find('.lined-title').css('display', 'flex');
      } else {
        $(this).parents().eq(1).find('.comments-container').addClass('is-active');
      }
    });

    var hash = location.hash;
    var view = button ? button.attr('data-profile-view') : hash.replace('#', '');

    if (view === 'members') {
        // $('.newsfeed-wrapper').addClass('is-showing-members');
    } else {
        $('.newsfeed-wrapper').removeClass('is-showing-members');
    }

    $('[data-profile-view]').removeClass('is-active');
    $('[data-profile-view="' + view + '"]').addClass('is-active');

    history.pushState(view, '', baseUrl + '#' + view);

    $("html, body").animate({ scrollTop: $('.newsfeed-wrapper').offset().top - $('.top-nav').outerHeight() - 350 });

    $('.newsfeed-view').fadeOut();
    $('.newsfeed-view.is-active').fadeIn(function () {
        if (view === 'members') {
            $('.newsfeed-wrapper').addClass('is-showing-members');
        } else {
            // $('.newsfeed-wrapper').removeClass('is-showing-members');
        }
    });
  };

  // Expose public methods & properties
  return {
    init: init
  };

}());
