window.site = (window.site || {});

/**
 * Utilities for SwitchToggleShow components
 * @class SwitchToggleShow
 * @static
 */
site.SwitchToggleShow = (function SwitchToggleShow() {

  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;
  var $switchs = $('[data-toggle-switch]');

  /**
   * Initializes the class.
   * @public
   */
  var init = function () {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    // Init();

    toggleViewState();

    $switchs.on('change', '[type="checkbox"]', function(event) {
      /* Act on the event */
      toggleViewState();
    });

    return true;

  };

  var toggleViewState = function () {

    $.each($switchs, function(index, val) {

      var $switch = $(this);
      var $container = $('[data-toggle-container][data-toggle-show="' + $(this).attr('data-toggle-show') + '"]');

      var isOn = $switch.find('[type="checkbox"]').prop('checked');

      
      if (isOn) {
        $container.slideDown(355);
      } else {
        $container.slideUp(355);
      }

    });
  }

  // Expose public methods & properties
  return {
    init: init
  };

}());
