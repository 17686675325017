window.site = (window.site || {});

/**
 * Utilities for Global App features
 * @class Global
 * @static
 */
site.Global = (function Global() {

  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;
  var loaderFaker = null;

  /**
   * Initializes the class.
   * @public
   */
  var init = function () {
    // $("main img").each((k, v) => {
    //   let bgimg = v.src;
    //   if (bgimg) {
    //     v.urlImage = bgimg;
    //     v.src = `/assets/images/loding.gif`;
    //     let img = new Image();
    //     img.src = bgimg;
    //     img.onload = function () {
    //       v.src = bgimg;
    //     }
    //   }
    // })
    // $("[style]").each((k, v) => {
    //   let bgimg = v.style.backgroundImage;
    //   if (bgimg) {
    //     v.urlImage = bgimg;
    //     let imgurl = bgimg.slice(4, -1).replace(/"/g, "");
    //     v.style.backgroundImage = `url(/assets/images/loding.gif)`;
    //     let img = new Image();
    //     img.src = imgurl;
    //     img.onload = function () {
    //       v.style.backgroundImage = bgimg;
    //     }
    //   }
    // })
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    // Init();

    // Top Nav Profile options
    $('body, html').on('click', function () {
      $('.top-nav-left').removeClass('is-active');
    });

    $('.top-nav-profile, .top-nav-options').on('click', function (event) {
      event.preventDefault();
      event.stopPropagation();
      $(this).parents('.top-nav-left').toggleClass('is-active');

      window.site.SystemNotifications.closeNotificationBox();
    });
    //


    // Easter EGG - Konami Code - Show hidden shadow
    const pressed = [];
    const secretCode = 'ArrowUpArrowUpArrowDownArrowDownArrowLeftArrowRightArrowLeftArrowRightba';

    /*window.addEventListener('keyup', (e) => {
            pressed.push(e.key);
      pressed.splice(-secretCode.length - 1, pressed.length - secretCode.length);
      if (pressed.join('').includes(secretCode)) {
                document.body.classList.add('hidden-shadow');
      }
      //     });*/


    // Aside follow on Newsfeed pages
    var $scope = $('.newsfeed-aside');
    if ($scope.length) {

      var isRemembered = false;
      var rememberMagicNumber = 0;

      function asideBarFollow(recalculate) {

        if ($(window).width() <= 914) {
          return;
        }

        var $aside = $('.newsfeed-aside');
        var windowHeight = $(window).height();
        var scrollTop = $(window).scrollTop();
        var asideTop = $aside.offset().top
        var asideHeight = $aside.outerHeight();
        var classCheck = $('.app-template').hasClass('app-details-page');

        if ($('.app-index').length != -1) {
          var magicNumber = ((asideTop + asideHeight) - windowHeight) + 115;
        } else {
          var magicNumber = ((asideTop + asideHeight) - windowHeight);
        }



        if (!isRemembered || recalculate) {
          rememberMagicNumber = magicNumber;
          isRemembered = true;
        }

        // 
        if (
          (!classCheck && scrollTop >= rememberMagicNumber && scrollTop <= $('.footer-newsletter').position().top - $('.footer').outerHeight()) ||
          ((classCheck && $('.newsfeed-posts').outerHeight() >= asideHeight)) && scrollTop >= rememberMagicNumber && scrollTop <= $('.footer-newsletter').position().top - $('.footer').outerHeight()) {
          $('.app-index .newsfeed-posts').addClass('is-fixed').css({ 'padding-right': $aside.outerWidth(), 'width': '100%' });
          $('.app-profile .newsfeed-posts, .app-details-page .newsfeed-posts').addClass('is-fixed').css({ 'padding-right': $aside.outerWidth() });
          $aside.addClass('is-fixed').css('right', $('.newsfeed-posts').offset().left);
          // $aside.css('top', scrollTop - rememberMagicNumber + 'px');
        } else {
          $('.app-index .newsfeed-posts').removeClass('is-fixed').css({ 'padding-right': 0, 'width': '60%' });
          $('.app-profile .newsfeed-posts, .app-details-page .newsfeed-posts').removeClass('is-fixed').css({ 'padding-right': 0 });
          $aside.removeClass('is-fixed').css('right', 0);
          // $aside.css('top', '0');
        }
      }

      //Run control on window scroll
      /* $(window).scroll(function () {
         asideBarFollow(false);
       });
 
       //Run control on window resize
       $(window).resize(function () {
         asideBarFollow(true);
       });
 
       $(window).load(function () {
         asideBarFollow(true);
 
         setTimeout(function () {
             asideBarFollow(true);
         }, 1235);
       });*/

    }

    $('.burger').on('click', function (event) {
      event.preventDefault();

      $('.burger').toggleClass('is-active');
      $('.mobile-nav').toggleClass('is-active');
      $('body').toggleClass('mobile-menu-is-active');

      if ($('body').hasClass('mobile-menu-is-active')) {
        $('#search-wrapper').hide();
        $('#filter-bar').hide();
        $('.header .top-nav').css('margin-top', '0');
      } else {
        $('#search-wrapper').show();
        $('#filter-bar').show();
        $('.header .top-nav').removeAttr('style');
      }
    });

    $(document).on('submit', '.form-newsletter', function (e) {
      e.preventDefault();
      var $action = $(this).find('.site-btn');

      if (!$(this).parsley({
        classHandler: function (el) {
          return el.$element.parent();
        }
      }).validate()) {
        return false;
      } else {
        $('[action-error]').fadeOut(300);
        $action.prop('disabled', true);
      }


      if ($action.hasClass('is-active') || $action.hasClass('is-loading')) {
        return;
      }

      $action.addClass('is-loading');

      var width = 1;
      loaderFaker = setInterval(load, 10);

      function load() {
        if (width >= 100) {
          clearInterval(loaderFaker);
        } else {
          width++;
          $('.loading-bar', $action).css('width', width + '%');
        }
      }

      var data = new FormData($(this)[0]);
      var form = $(this);

      var request = $.ajax({
        url: '/mailing/subscribeNewsletter',
        type: 'POST',
        data: data,
        cache: false,
        contentType: false,
        processData: false,
      });

      request.done(function (response) {
        var r = $.parseJSON(response);

        if (r.status && r.status === 'success') {
          form.fadeOut(300);
          $action.prop('disabled', false);

          $('.loading-bar', $action).css('width', '100%');
          setTimeout(function () {
            clearInterval(loaderFaker);
            $action.toggleClass('is-active');
            $('[action-success]').fadeIn(300);

          }, 300);
        }
        else if (r.status && r.status == 'error') {
          $action.prop('disabled', false);
          $action.removeClass('is-loading');

          $('[action-error]').fadeIn(300);
          $('[action-error-text]').text(r.message)
        }
      });

    });

    // Activate Follow Sticky

    if (document.querySelector('#main-header')) {
      activateMenuFollow();
    }

    return true;

  };

  const activateMenuFollow = function () {

    const $nav = document.querySelector('#main-header');
    const buffer = 10;

    let lastScrollTop = 0;

    let st = document.documentElement.scrollTop;

    if (st >= 158) {
      $nav.classList.add('is-sticky');
      // document.querySelector('.app-template').style.paddingTop = $nav.clientHeight + 'px';
    } else {
      $nav.classList.remove('is-sticky');
      // document.querySelector('.app-template').style.paddingTop = '0';
    }

    window.addEventListener("scroll", () => {

      let st = document.documentElement.scrollTop;

      // 
      if (st >= 158) {
        $nav.classList.add('is-sticky');
        // document.querySelector('.app-template').style.paddingTop = $nav.clientHeight + 'px';
      } else {
        $nav.classList.remove('is-sticky');
        // document.querySelector('.app-template').style.paddingTop = '0';
      }

      if (st > lastScrollTop) {
        // downscroll code
        $nav.classList.add("hide-nav-bar");
      } else if (st < (lastScrollTop - buffer)) {
        // upscroll code
        $nav.classList.remove("hide-nav-bar");
      }

      if (st <= 15) {
        $nav.classList.remove("hide-nav-bar");
      }

      lastScrollTop = st;
    });
  };

  // Expose public methods & properties
  return {
    init: init
  };

}());
