window.site = (window.site || {});

/**
 * Utilities for SystemNotifications components
 * @class SystemNotifications
 * @static
 */
site.SystemNotifications = (function SystemNotifications() {

  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;
  var isVisible = false;

  var $notificationCounter = $('.notification-counter-wrapper');
  var $notificationsWrapper = $('#system-notifications');
  var $notificationClick = $('[action-notification-click]');

  var openNotificationsBtn = '[data-open-notifications]';
  var notificationBox = '.systemNotifications-drop';
  var notificationItem = '.systemNotifications-drop-notification';

  /**
   * Initializes the class.
   * @public
   */
  var init = function () {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    // Init();
    onPageReadyRefreshCounter();

    // Also always check every 30 secconds
    var checkInterval = setInterval(function() {
      onPageReadyRefreshCounter();
    }, 10000);

    // Actions
    $('body').on('click', function(event) {
      closeNotificationBox();
    });

    $('body').on('click', openNotificationsBtn, function(event) {
      event.preventDefault();
      event.stopPropagation();

      (isVisible) ? closeNotificationBox() : openNotificationBox();

    });

    $('body').on('click', notificationItem, function(event) {
      event.preventDefault();
      event.stopPropagation();

      closeNotificationBox();
      clickNotification($(this).find('a'));
      // window.location.href = .attr('href');
    });

    $('body').on('click', notificationBox, function(event) {
      event.stopPropagation();
    });

    return true;

  };

  var onPageReadyRefreshCounter = function () {

    // Refresh Counter component
    $notificationCounter.load("/_app/_partials/system-notifications/notification-badge.twig");

    $('.systemNotifications-drop-content').load("/_app/_partials/system-notifications/notification.twig", function() {
      if (isVisible) {
        $notificationsWrapper.find(notificationBox).addClass('is-active');
      }
    });

  };

  var openNotificationBox = function () {

    var $notificationsList = $(notificationBox).find('.systemNotifications-drop-notification');

    // Don't open if nothing to see...
    if ( !$notificationsList.length ) {
      return false;
    }

    $(notificationBox).addClass('is-active');
    $('.for-notifications').addClass('is-active');
    isVisible = true;

    // Close profile popup
    $('.top-nav-profile, .top-nav-options').parents('.top-nav-left').removeClass('is-active');
  };

  var closeNotificationBox = function () {

    var $notificationsList = $(notificationBox).find('.systemNotifications-drop-notification');
    var notificationIds = [];

    var notificationHasBeenSeen = false;

    if (isVisible) {
      notificationHasBeenSeen = true;
    }

    $(notificationBox).removeClass('is-active');
    $('.for-notifications').removeClass('is-active');
    isVisible = false;

    $.each($notificationsList, function(index, val) {
      notificationIds.push($(this).attr('data-notification-id'));
    });

    if (notificationHasBeenSeen) {
      $.ajax({
        url: '/action/systemNotifications/view',
        type: 'GET',
        dataType: 'json',
        data: {
          notificationIds: notificationIds
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
      })
      .done(function() {
        onPageReadyRefreshCounter();
              })
      .fail(function() {
              })
      .always(function() {
        onPageReadyRefreshCounter();
              });
    }

  };

  var clickNotification = function (notification) {

      $.ajax({
        url: '/action/systemNotifications/clickedNotification',
        type: 'GET',
        dataType: 'json',
        data: {
          notificationId: notification.attr('data-notification-click-id')
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
      })
      .done(function() {
        window.location.href = notification.attr('href');
              })
      .fail(function() {
              })
      .always(function() {
              });

  };

  // Expose public methods & properties
  return {
    init: init,
    'refreshCounter': onPageReadyRefreshCounter,
    'closeNotificationBox': closeNotificationBox
  };

}());
