


window.site = (window.site || {});

/**
 * Utilities for Dashboard pages
 * @class Dashboard
 * @static
 */
site.Dashboard = (function Dashboard() {

  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  var $cardToggle = $('.js-card-toggle');
  var $overviewNav = $('.dashboard-overview a[data-overview]');
  var $closeOverview = $('.action-close-tab');
  var loaderFaker = null;
  var $country = $('#country');
  var $provinces = $('#province');
  var $parentOverview = $('[action-overview-parent]');
  var $childrenOverview = $('[action-overview-children]');
  var $thankYouBanner = $('.app-thankyou');
  function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  let path = location.pathname.split('/');
  path = path.slice(1);
  if (!getCookie('site_locale')) {
    if (path[0] == 'fr') {
      setCookie('site_locale', 'fr', 30);
    } else {
      setCookie('site_locale', 'en', 30);
    }
  }
  function resize() {
    let main = document.querySelector('.main');
    let mainheader = document.querySelector('#main-header');
    if (main) {
      if (mainheader) {
        let p = mainheader.offsetHeight - 63;
        main.style.paddingTop = `${p}px`;
      }
    }
  }
  resize();
  addEventListener('resize', resize)
  /**
   * Initializes the class.
   * @public
   */
  let languageswitcher = document.querySelectorAll('.language-switcher');
  if (languageswitcher) {
    languageswitcher.forEach(value => {
      value.addEventListener('click', () => {
        let path = location.pathname.split('/');
        path = path.slice(1);
        if (path[0] == 'fr') {
          setCookie('site_locale', 'en', 30);
        } else {
          setCookie('site_locale', 'fr', 30);
        }
      })
    })
  }
  let alertheader = document.querySelector('.alert-container')
  if (alertheader) {
    let alertbuttonclose = alertheader.querySelector('.alert-button-close')
    if (alertbuttonclose) {
      alertbuttonclose.addEventListener('click', () => {
        setCookie('site_alert', 'true', 30);
        alertheader.style.maxHeight = '0';
        setTimeout(() => {
          alertheader.remove();
        }, 300)
      })
    }
  }
  var init = function () {
    let profile_form = document.querySelector('#profile_form');
    let save_language = document.querySelector('#save_language');
    if (save_language) {
      save_language.addEventListener('click', function (e) {
        if (profile_form) {
          let path = location.pathname.split('/');
          path = path.slice(1);
          if (path[0] == 'fr') {
            path = path.slice(1);
          }
          if (profile_form['fields[preferredLanguage]'][1].checked) {
            setCookie('site_locale', 'fr', 30);
            history.pushState('', '', '/fr/' + path.join('/'))
          } else {
            setCookie('site_locale', 'en', 30)
            history.pushState('', '', '/' + path.join('/'))
          }
        }
      })

    }
    // Abort if already initialized
    if (inited) {
      return false;
    }


    inited = true;

    $('[action-select-admin]').select2({
      placeholder: $(this).attr('placeholder'),
      maximumSelectionLength: 1,
      height: '50px',
      width: '100%'
    });

    if ($thankYouBanner.length) {
      $(document).on('scroll', function (e) {
        $thankYouBanner.fadeOut();
      });
    }

    // Init();
    $('.profile-form input:not(.stripe-input), .profile-form select:not(.stripe-input), .profile-form textarea').on('change keyup', function () {
      $(this).closest('.dashboard-card').find('.save-form').prop('disabled', false);
      $(this).closest('.profile-box-header').find('.save-form').prop('disabled', false).addClass('is-active');
    });

    var allowSubmit = true;

    $('.profile-form').on('submit', function (e) {
      if (allowSubmit) {
        allowSubmit = false;
      } else {
        return false;
      }
    });

    /*$cardToggle.on('click', function(e){
      e.preventDefault();
      $(this).parents().eq(2).addClass('is-flipped');
    });*/

    $('.profile-form.infos-form').on('change', 'input, textarea, select', function (event) {
      $('.js-sticky-submit').addClass('is-active');
    });

    $('.profile-form.infos-form').on('keyup', 'input, textarea, [contenteditable="true"]', function (event) {
      $('.js-sticky-submit').addClass('is-active');
    });

    // Request Access
    $(document).on('click', '.action-accept-request, .action-decline-request, .action-remove-request', function (e) {
      e.preventDefault();

      var $action = $(this);
      var shouldRefresh = ($action.attr('data-should-refresh') === '1');


      if ($action.hasClass('is-active') || $action.hasClass('is-loading')) {
        return;
      }


      $action.addClass('is-loading');

      var width = 1;
      loaderFaker = setInterval(load, 10);

      var currentSection = $action.closest('.dashboard-grid-view');


      var currentCounter = $('.dashboard-overview a[data-overview="' + currentSection.data('overview') + '"]').find('.post-count');

      function load() {
        if (width >= 100) {
          clearInterval(loaderFaker);
        } else {
          width++;
          $('.loading-bar', $action).css('width', width + '%');
        }
      }

      $.get($action.attr('href'), function (data) {
        /*optional stuff to do after success */
        $('.loading-bar', $action).css('width', '100%');
        setTimeout(function () {
          clearInterval(loaderFaker);
          $action.toggleClass('is-active');

          var followName = $action.attr('data-follow-name');

          if ($action.hasClass('action-accept-request')) {
            toastr.success('You are now connected');
            window.dataLayer = window.dataLayer || [];
            dataLayer.push({
              'event': 'formSubmission',
              'category': 'accept-user-request',
              'action': 'send',
              'label': 'form'
            });

          } else if ($action.hasClass('action-decline-request')) {
            toastr.success('Connection declined');
            window.dataLayer = window.dataLayer || [];
            dataLayer.push({
              'event': 'formSubmission',
              'category': 'decline-user-request',
              'action': 'send',
              'label': 'form'
            });
          } else if ($action.hasClass('action-remove-request')) {
            toastr.success('Connection removed');
            window.dataLayer = window.dataLayer || [];
            dataLayer.push({
              'event': 'formSubmission',
              'category': 'removed-user-request',
              'action': 'send',
              'label': 'form'
            });
          }

          if ($action.closest('.posts-feed-ajax').length > 0) {
            $action.parents().eq(1).delay(800).slideUp('medium');
          } else {
            $action.siblings('a.is-progress').attr('href', '#').addClass('is-disabled');
          }



          currentCounter.text(parseInt(currentCounter.text()) - 1);

          if (shouldRefresh) {
            location.reload();
          }
        }, 500);
      });
    });

    // Request Access
    $(document).on('click', '.action-request, [data-action-request]', function (e) {
      e.preventDefault();

      var $action = $(this);

      if ($action.hasClass('is-active') || $action.hasClass('is-loading')) {
        return;
      }

      $action.addClass('is-loading');

      var width = 1;
      loaderFaker = setInterval(load, 10);

      function load() {
        if (width >= 100) {
          clearInterval(loaderFaker);
        } else {
          width++;
          $('.loading-bar', $action).css('width', width + '%');
        }
      }
    });

    $overviewNav.on('click', function (event) {
      event.preventDefault();

      if (!$(this).hasClass('is-active')) {
        $overviewNav.removeClass('is-active');
        $(this).addClass('is-active');
      } else {
        $overviewNav.removeClass('is-active');
      }

      openOverview();
    });

    $closeOverview.on('click', function (e) {
      e.preventDefault();
      $overviewNav.removeClass('is-active');
      $('.dashboard-grid-views').removeClass('is-active');
    });

    if ($country.val() === 'Canada') {
      $('.us-states').hide();
      $('.canada-provinces').show();
    } else if ($country.val() === 'United States') {
      $('.canada-provinces').hide();
      $('.us-states').show();
    }

    $country.on('change', function () {
      statesToggler($(this).val());
    });


    var allowSubmit = true;

    if (allowSubmit && $('.business-create-form').length) {
      $('.business-create-form').on('submit', function () {
        allowSubmit = false;
        var $currentForm = $(this);

        $currentForm.parsley().on('form:success', function () {
          $currentForm.find('button').text('Generating Business Page...');
        });

        $currentForm.parsley().on('form:error', function () {
          allowSubmit = true;
        });
      });
    }


    $(document).on('click', '[data-alert-before]', function (e) {
      e.preventDefault();
      var href = $(this).attr('href');
      swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this post.",
        icon: "warning",
        buttons: true,
        dangerMode: true
      })
        .then((willDelete) => {
          if (willDelete) {
            $.get(href, function (data) {
              location.reload();
            });
            return true;
          } else {
            return false;
          }
        });
    });
    //Switch between provinces and states

    $parentOverview.on('click', function (e) {
      e.preventDefault();
      $parentOverview.not($(this)).removeClass('is-active').next().slideUp();
      $('a[data-overview].is-active').removeClass('is-active');

      if (!$(this).hasClass('is-active')) {
        $(this).toggleClass('is-active');
        $(this).next().slideToggle();
      } else {
        $parentOverview.removeClass('is-active').next().slideUp();
      }

      openOverview();
    });

    var businessData = '';

    if ($('[data-has-business]').length) {
      businessData += '<h2 class="medium-title">Warning! You have a business profile associated to your account.</h2>';
      businessData += '<p class="regular-text">It seems you are the admin of a business account, if you want to transfer it to another account, please choose from a user list below before deleting :';
      businessData += '<div class="list-holder"></div>';
    }

    /*$('[action-transfer-user]').confirm({
        title: 'Warning! You\'re about to transfer your business ownership',
        content: '<p>Are you sure you want to transfer your account ownership to another user? You will be unable to access the business dashboard anymore if you transfer your ownership.</p>',
        buttons: {
            confirm: {
                text: 'Yes',
                btnClass: 'site-btn',
                action: function() {
                  $('#transfer-user').submit();
                }
            },
            cancel: function () {

            }
        }
    })*/


    $('[action-add-admin]').confirm({
      title: 'You\'re about to add an admin to your business page',
      content: '<p class="regular-text"></p>',
      confirmButton: 'Yes',
      cancelButton: 'No',
      buttons: {
        confirm: function () {

          var data = $('.admin-select-form').serialize();

          $.ajax({
            method: 'POST',
            url: '/user/addAdmin',
            data: data,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
          })
            .success(function (data) {
              var text = '';

              $.alert({
                title: 'You have added an admin for your business page',
                content: '<p class="regular-text">The assigned user can now have access to the business dashboard and edits it\'s contents. Your page will refresh in a few moments to reflect these changes.</p>',
                onContentReady: function () {
                  setTimeout(function () {
                    location.reload();
                  }, 2500);
                }
              })


            })
            .error(function (data) {
              $.alert('<h2 class="medium-title">' + data + '</h2>');
            })
        },
        cancel: function () {
        },
      },
      onOpenBefore: function () {
        var selectedName = $('[action-select-admin]').find(':selected').text();
        var businessName = $('[data-business-name]').data('business-name');
        this.$content.find('.regular-text').text('Are you sure you want to add ' + selectedName + ' as an admin for ' + businessName + ' the business page')
        $('.jconfirm-buttons .btn-default:eq(0)').text('Yes');
        $('.jconfirm-buttons .btn-default:eq(1)').text('No');
      }
    });

    $('[action-remove-admin]').confirm({
      title: 'You\'re about to remove an admin to your business page',
      content: '<p class="regular-text"></p>',
      confirmButton: 'Yes',
      cancelButton: 'No',
      buttons: {
        confirm: function () {

          var data = $('.admin-select-form').serialize();

          $.ajax({
            method: 'POST',
            url: '/user/removeAdmin',
            data: data,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
          })
            .success(function (data) {
              var text = '';

              $.alert({
                title: 'You have removed the admin for your business page',
                content: '<p class="regular-text">The assigned user\'s admin rights has now been revoked, you can now choose to add a new admin. Your page will refresh in a few moments to reflect these changes.</p>',
                onContentReady: function () {
                  setTimeout(function () {
                    location.reload();
                  }, 2500);
                }
              })


            })
            .error(function (data) {
              $.alert('<h2 class="medium-title">' + data + '</h2>');
            })
        },
        cancel: function () {
        },
      },
      onOpenBefore: function () {
        var selectedName = $('[data-admin-name]').data('admin-name');
        var businessName = $('[data-business-name]').data('business-name');
        this.$content.find('.regular-text').text('Are you sure you want to remove ' + selectedName + ' as an admin for ' + businessName + ' the business page')
        $('.jconfirm-buttons .btn-default:eq(0)').text('Yes');
        $('.jconfirm-buttons .btn-default:eq(1)').text('No');
      }
    });

    $('[action-delete-user]').confirm({
      title: 'Warning! You\'re about to delete your account',
      content: '' +
        '<form action="" class="delete-user-form" method="post">' +
        '<div class="form-group input-field">' +
        '<p class="regular-text">Are you sure you want to delete your user account? By doing this, everything that you have posted on this website will be permanently deleted. If you acknowledge and fully understand that you won\'t be able to retrieve the lost data and commit to deleting your account, please type down "DELETE" in the box below :</p>' +
        '<input type="text" placeholder="DELETE" class="confirmation form-control" required /><br/>' +
        businessData +
        '<input type="hidden" name="userId" value="' + $('[action-delete-user]').data('userid') + '" />' +
        '<input type="hidden" name="businessId" value="' + $('[action-delete-user]').data('businessid') + '" />' +
        '<input type="hidden" name="site_csrf" value="' + site.App.config('csrf') + '" />' +
        '</div>' +
        '</form>',
      buttons: {
        formSubmit: {
          text: 'Submit',
          btnClass: 'site-btn',
          action: function () {
            var confirmText = this.$content.find('.confirmation').val();
            if (!confirmText || confirmText != 'DELETE') {
              $.alert('Please write down DELETE to delete your account');
              return false;
            }

            this.$content.find('form').submit();
          }
        },
        cancel: function () {
          //close
        },
      },
      onContentReady: function () {
        // bind to events

        $('#business-employees').clone().attr('id', 'js-confirm-employees').appendTo('.list-holder');
        $('#js-confirm-employees').css('display', 'block').find('select').attr('name', 'transferId');

        var jc = this;
        this.$content.find('form').on('submit', function (e) {
          // if the user submits the form by pressing enter in the field.
          e.preventDefault();

          var data = $(this).serialize();

          $.ajax({
            method: 'POST',
            url: '/user/deleteAccount',
            data: data,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
          })
            .success(function (data) {
              var text = '';

              if ($('[data-is-business]').length) {
                text = 'You will shortly be redirected to your member profile';
              } else {
                text = 'You will shortly be redirected to the homepage'
              }

              $.alert({
                title: 'Your account has now been deleted',
                content: '<p class="regurlar-text">' + text + '</p>',
                onContentReady: function () {
                  setTimeout(function () {
                    if ($('[data-is-business]').length) {
                      window.location.replace("/dashboard");
                    } else {
                      window.location.replace("/discover");
                    }
                  }, 2000);
                }
              })
            })
            .error(function (data) {
              $.alert('<h2 class="medium-title">And error has occured, please try again</h2>');
            })
          // jc.$$formSubmit.trigger('click'); // reference the button and click it
        });
      }
    });

    return true;

  };

  var openOverview = function () {
    var $activeLink = $('.dashboard-overview a[data-overview].is-active');

    if ($activeLink.attr('data-overview') === 'pConnections') {
      // Reset Connections notifications counter
      $.get('/user/resetConnectionsNotificationsCounter', function (data, textStatus, xhr) {
        /*optional stuff to do after success */
        window.site.LiveNotificationsCounter.refreshCounter();
        $activeLink.find('.notification-circle').remove();
      });
    }

    if ($activeLink.length) {
      var data = $activeLink.attr('data-overview');

      $('.dashboard-grid-views').addClass('is-active');
      $('.dashboard-grid-view[data-overview]').removeClass('is-active');
      $('.dashboard-grid-view[data-overview="' + data + '"]').addClass('is-active');
    } else {
      $('.dashboard-grid-views').removeClass('is-active');
    }
  };

  var statesToggler = function (value) {
    if (value === 'Canada') {
      $('.us-states').hide();
      $('.canada-provinces').show();
    } else if (value === 'United States') {
      $('.canada-provinces').hide();
      $('.us-states').show();
    }
  };

  // Expose public methods & properties
  return {
    init: init
  };

}());
