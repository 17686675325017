window.site = (window.site || {});

/**
 * Utilities for Newsfeed components
 * @class Newsfeed
 * @static
 */
site.Newsfeed = (function Newsfeed() {

  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;
  var $pager = $('[data-pager]');
  var loaderFaker = null;

  /**
   * Initializes the class.
   * @public
   */
  var init = function () {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    // Scroll to feed if filtered
    if ($('body').hasClass('is-filtered')) {
      setTimeout(function () {
        $('html, body').animate({ scrollTop: $('div.filters').offset().top - 105 }, 755);
      }, 2500);
    }


    $('#inviteColleagueForm button').on('click', function (e) {

      // e.preventDefault();
      if (grecaptcha.getResponse().length === 0) {
        $('#error-captcha').css('display', 'block');
      } else {
        var $action = $(this);
        var $form = $action.parents('form');

        if (!$form.parsley().validate()) {
          return false;
        }

        if ($action.hasClass('is-active') || $action.hasClass('is-loading')) {
          return;
        }

        $action.addClass('is-loading').prop('disabled', true);

        var width = 1;
        loaderFaker = setInterval(load, 10);

        function load() {
          if (width >= 100) {
            clearInterval(loaderFaker);
          } else {
            width++;
            $('.loading-bar', $action).css('width', width + '%');
          }
        }

        $.ajax({
          type: 'post',
          url: $('[name="action"]', $form).val(),
          data: $form.serializeArray(),
          success: function (response) {


            if (!response.success) {
              // Re-Enable send button
              $action.removeClass('is-loading is-active').prop('disabled', false);

              return false;
            }

            // Re-Enable send button
            $action.removeClass('is-loading is-active').prop('disabled', false);
            $('.loading-bar', $action).css('width', '100%');

            toastr.success('Your message has been sent');

            var $email = $('#currentUserEmail').val();

            window.dataLayer = window.dataLayer || [];
            dataLayer.push({
              'event': 'inviteColleague',
            });

            if ($email) {
              dataLayer.push({
                'email': '"' + $email + '"',
              });
            }

            $form[0].reset();
            clearInterval(loaderFaker);
            $('body').removeClass('has-overlay');
            $('.overlay-modal[data-overlay="invite-form"]').removeClass('is-active');
            return true;
          }
        });
      }

    });
    let newsfeedlistpagination = document.querySelectorAll('.newsfeed-list-pagination');
    if (newsfeedlistpagination) {
      newsfeedlistpagination.forEach(pagination => {
        let listnewsFeed = pagination.querySelectorAll('.newsfeed-list-link')
        let is_loading = false;
        if (listnewsFeed) {
          let bk_parentElement;
          listnewsFeed.forEach((element) => {
            if (element.parentElement.classList.contains('current')) {
              bk_parentElement = element.parentElement
            }
            element.addEventListener('click', function (e) {
              e.preventDefault();
              if (is_loading) return false;
              is_loading = true;
              let content = $(this.getAttribute('data-id'));
              if (bk_parentElement) {
                bk_parentElement.classList.remove('current')
              }
              bk_parentElement = this.parentElement;
              bk_parentElement.classList.add('current');
              content.addClass('loading')
              let self = this;
              self.classList.add('btn-loading');
              $.get(this.href, function (result) {
                content.html(result);
                content.removeClass('loading')
                self.classList.remove('btn-loading');
                is_loading = false;
                $('html, body').animate({
                  scrollTop: content.offset().top - 100
                }, 500);
              }).fail(function () {
                content.removeClass('loading')
                self.classList.remove('btn-loading');
                is_loading = false;
                alert("error");
              });
            })
          })
        }
      });

    }

    // Init();
    $.each($pager, function (index, val) {

      $(this).on('click', function (event) {
        event.preventDefault();

        var $page = $(this);
        var pageNb = parseInt($(this).attr('data-page'));
        var url = $page.attr('href');
        var dataPagerContainer = $page.attr('data-pager-container');
        var dataPagerItemSelector = $page.attr('data-pager-item-selector');
        var $loader = $(`<div class="loading-effect" >
                          <div class="sk-folding-cube">
                            <div class="sk-cube1 sk-cube"></div>
                            <div class="sk-cube2 sk-cube"></div>
                            <div class="sk-cube4 sk-cube"></div>
                            <div class="sk-cube3 sk-cube"></div>
                          </div>
                        </div>`);

        $page.hide();

        $loader.insertBefore($page);
        if (!this.page) this.page = 1;
        this.page++;
        if (this.hasAttribute('data-ajax')) {
          let lang = document.documentElement.lang;
          $.get(`/${lang}/newsfeedlist/p` + this.page, function (result) {
            $('#article-posts-feed-ajax').append(result);
            if (result) {
              $page.show();
            }
            $('.loading-effect').remove();
          });

        } else {
          // $.get('/newsfeedlist/p' + this.page+'?videos=true', function (result) {
          //   $('#posts-feed-ajax').append(result);
          //   if (result) {
          //     $page.show();
          //   }
          //   $('.loading-effect').remove();
          // });

          $.get(url, function (result) {

            //
            $(dataPagerContainer).append($(result).find(dataPagerContainer + ' ' + dataPagerItemSelector));

            //
            $.each($('div[data-read-more] >.comments-list'), function () {

              if ($(this).height() > 515) {
                $(this).parents().eq(1).find('.lined-title').css('display', 'flex');
              } else {
                $(this).parents().eq(1).find('.comments-container').addClass('is-active');
              }
            });


            if ($(result).find(dataPagerContainer + '+' + '.newsfeed-pagination').find('[data-pager]').length) {
              $page.attr('href', $(result).find(dataPagerContainer + '+' + '.newsfeed-pagination').find('[data-pager]').attr('href'));
              $page.show();
              $('.loading-effect').remove();
            } else {
              $('.loading-effect').remove();
            }
          });
        }

      });

    });

    return true;

  };

  // Expose public methods & properties
  return {
    init: init
  };

}());
