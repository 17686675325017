window.site = (window.site || {});

/**
 * Utilities for Auth components
 * @class Auth
 * @static
 */
site.Auth = (function Auth() {

  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;
  var $authPopup = $('#auth-popup');
  var $authTabs = $('[data-nav-tab]');
  var $togglePassword = $('.toggle-password');
  var $toggleFlip = $('[data-toggle-flip]');
  var $toggleFlipSecond = $('[data-toggle-flip-second]');
  var $validate = $('[data-validate-next]');
  var $toggleNextSteps = $('[data-toggle-next-steps]');
  var $togglePrevSteps = $('[data-toggle-prev-steps]');
  var $tooltip = $('[data-trigger-tooltip]');
  var $avoToggle = $('[action-toggle-avo]');
  var $specialtySelect = $('[action-specialty-select]');
  var $specialtyLabel = $('[action-speciality-label]'),
      $schoolLabel = $('[action-school-label]'),
      $gradLabel = $('[action-graduation-label]');

  var $schoolGroup = $('[action-school-fields]'),
      $gradGroup = $('[action-grad-fields]'),
      $licenseGroup = $('[action-npi-fields]');

  /**
   * Initializes the class.
   * @public
   */
  var init = function () {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    $tooltip.tooltipster({
        theme: 'tooltipster-borderless',
        trigger: 'custom',
        triggerOpen: {
          mouseenter: true,
          tap: true,
          click: true,
        },
        triggerClose: {
          mouseenter: true,
          tap: true,
          click: true,
          scroll: true
        }
    });



    // Init();
    $('[action-cookie-init]').on('submit', function(e){
      window.dataLayer = window.dataLayer || [];

      dataLayer.push({
        'pageType': 'Sign-up',
        'userStatus': 'NotLogged'
    });

      var halfDay = 0.5;
      Cookies.set('reg_email', encodeURIComponent($(this).find('#loginName').val()), {expires: 1, path: '', secure: true});
    });

    // Start of Area of Specialty Logic
    $(document).on('change', '#userJobArea', function(e){
      var selectedIndex = $(this).val();

      if($.inArray(parseInt(selectedIndex), [14759, 14760, 14761, 14762, 14763]) > -1) {
                $specialtyLabel.text('Primary area of practice');

        //Change Graduation label
        if($gradGroup.length) {
          $gradGroup.show();
          $gradGroup.find($gradLabel).text('Year of graduation');
          $gradGroup.find('input').attr('placeholder','Year of graduation');
        }

        //Change School Label
        if($schoolGroup.length) {
          $schoolGroup.show();
          $schoolGroup.find($schoolLabel).text('Medical School (where you practice)');
          $schoolGroup.find('input').attr('placeholder','Medical School (where you practice)');
        }

        $licenseGroup.show();
        $licenseGroup.find('input').prop('disabled',false);
      }
      else if ($.inArray(parseInt(selectedIndex), [14764, 14765]) > -1) {
                $specialtyLabel.text('Field of study');

        //Change Graduation label
        if($gradGroup.length) {
          $gradGroup.show();
          $gradGroup.find($gradLabel).text('Year of actual/planned graduation');
          $gradGroup.find('input').attr('placeholder','Year of actual/planned graduation');
        }

        //Change School Label
        if($schoolGroup.length) {
          $schoolGroup.show();
          $schoolGroup.find($schoolLabel).text('Medical School (where you practice)');
          $schoolGroup.find('input').attr('placeholder','Medical School (where you practice)');
        }

        $licenseGroup.hide();
        $licenseGroup.find('input').prop('disabled',true);
      }
      else if ($.inArray(parseInt(selectedIndex), [14766]) > -1) {
                $specialtyLabel.text('Field of research');

        //Change Graduation label
        if($gradGroup.length) {
          $gradGroup.show();
          $gradGroup.find($gradLabel).text('Year of graduation');
          $gradGroup.find('input').attr('placeholder','Year of graduation');
        }

        //Change School Label
        if($schoolGroup.length) {
          $schoolGroup.show();
          $schoolGroup.find($schoolLabel).text('Medical School/Organization (where you practice)');
          $schoolGroup.find('input').attr('placeholder','Medical School/Organization (where you practice)');
        }

        $licenseGroup.hide();
        $licenseGroup.find('input').prop('disabled',true);
      }
      else {
                $specialtyLabel.text('Medical field related to your activities');

        $gradGroup.hide();
        $schoolGroup.hide();
        $licenseGroup.hide();

        $licenseGroup.find('input').prop('disabled',true);
      }

      if(parseInt(selectedIndex) == 14770) {
                $('#userPatients, #userJobYears').removeAttr('required');
      }
      else {
                $('#userPatients, #userJobYears').prop('required', true);
      }
    }) ;

    $.each( $('.auth select[multiple], .profile-form select[multiple]'), function(index, val) {

      $(this).select2({
        placeholder: $(this).attr('placeholder'),
        height: '50px'
      });
    });

    $avoToggle.on('change', function() {
      if($(this).prop('checked')) {
                $('[action-avo-update]').attr('href', '/discover?ageVerification&rememberMe=true')
      }else {
                $('[action-avo-update]').attr('href', '/discover?ageVerification')
      }
    });

    $authPopup.hide();

    $('[data-auth-form]').hide();
    $('[data-auth-form="login"]').show();

    $authTabs.on('click', function(event) {
      event.preventDefault();

      var selectedForm = $(this).attr('data-nav-tab');

      $authTabs.removeClass('is-active');
      $('[data-nav-tab="' + selectedForm + '"]').addClass('is-active');

      $('[data-auth-form]').hide();

      $('[data-auth-form="' + selectedForm + '"]').show();

      $authPopup.show();
    });

    $togglePassword.on('click', function(e) {
      e.preventDefault();

      if($(this).find('i').hasClass('icon-eye')) {
        $(this).find('i').toggleClass('icon-eye icon-eye-blocked');
        $(this).prev().attr('type','text');
      }else {
        $(this).find('i').toggleClass('icon-eye icon-eye-blocked');
          $(this).prev().attr('type','password');
      }
    });

    /*$toggleFlip.on('click', function(event) {
      event.preventDefault();
      $(this).parents('.auth-flipper-container').toggleClass('is-flipped');
    });

    $toggleFlipSecond.on('click', function(event) {
      event.preventDefault();
      $(this).parents('.auth-flipper-container').toggleClass('is-flipped');
    });*/

    $validate.on('click', function(event) {
      event.preventDefault();
      var $form = $(this).parents('form');
      validateNext($form);
    });

    /*$togglePrevSteps.on('click', function(event) {
      event.preventDefault();
      var $form = $(this).parents('form');
      validateNext($form);
    });

    $togglePrevSteps.on('click', function (e){
      e.preventDefault();

      $('select.is-optional').select2('destroy').attr('required', false).val('');

      $('[data-default-next]').show();
      $('[data-alternate-next]').hide();


      var $form = $(this).parents('form');
      // $form.find('[name="redirect"]').val('/auth/thank-you');
    })*/

    /*$toggleNextSteps.on('click', function (event) {
      event.preventDefault();

      /!*      if($('#interests').length) {
                $('#interests').select2({
          placeholder: $(this).attr('placeholder'),
          height: '50px'
        });
      }*!/

      if ($('.auth .auth-flipper-container').hasClass('is-flipped-second')) {
        $('select.is-optional').attr('required', true).select2({
          placeholder: $(this).attr('placeholder'),
          maximumSelectionLength: 3,
          height: '50px'
        });
      } else {
        $('select.is-optional:not(#interests)').select2('destroy').attr('required', false).val('');
      }

      $('[data-default-next]').hide();
      $('[data-alternate-next]').show();

      // <input type="hidden" name="redirect" value="/auth/thank-you">

      var $form = $(this).parents('form');
      // $form.find('[name="redirect"]').val('/auth/thank-you');
      // validateNext($form);
    });*/

    $('[data-send-form]').on('click', function(event) {
      var $form = $('.auth-wrapper form');
      validateNext($form);
    });

    // $(document).on('change keyup', '.auth-flipper-container input, auth-flipper-container select', function(event) {
    //   event.preventDefault();
    //   var $form = $(this).parents('form');
    //   validateNext($form);
    // });
    function getCookie(cName) {
      const name = cName + "=";
      const cDecoded = decodeURIComponent(document.cookie); //to be careful
      const cArr = cDecoded.split('; ');
      let res;
      cArr.forEach(val => {
        if (val.indexOf(name) === 0) res = val.substring(name.length);
      })
      return res
    }
    if (getCookie('site_locale') === 'en'){
      var title= "You must be Logged in";
      var btn_thanks = 'No Thanks';
      var btn_sign_up = 'Sign Up';
      var btn_close = 'Close';
      var msg_modal = "<p>You must be a registered member to complete this action.</p> <a data-track data-action=\"click\" data-category=\"login\" data-label=\"overlay\" href='/auth/login' class='modal-links'>Log In</a> <br> <p>Not a member? Register FREE now!</p>";
      var cme_message = "<p>Please log in or register as a member on OncoXchange to access our CME program selection.</p>";
      var cme_title = "The CME Program section is only available to registered members.";
      var cme_btn_yes = "Yes please";
      var home_modal_message = "<p>You must be a registered member to have access to more content.</p> <a data-track data-action=\"click\" data-category=\"scrolled-login\" data-label=\"overlay\" href='/auth/login' class='modal-links'>Log In</a> <br> <p>Not a member? Register FREE now!</p>";
      var home_modal_title = "Want to view more?";
    }else{
      var title= "Vous devez être connecté";
      var btn_thanks = 'Non Merci';
      var btn_sign_up = 'Inscription';
      var btn_close = 'Fermer';
      var msg_modal = "<p>Vous devez être un membre enregistré pour effectuer cette action.</p> <a data-track data-action=\"click\" data-category=\"login\" data-label=\"overlay\" href='/fr/auth/login' class='modal-links'>Connexion</a> <br> <p>Pas un membre? Inscrivez-vous GRATUITEMENT maintenant !</p>";
      var cme_message = "<p>Veuillez vous connecter ou vous inscrire en tant que membre sur OncoXchange pour accéder à notre sélection de programmes FMC.</p>";
        var cme_title = "La section Programme FMC n'est disponible que pour les membres inscrits.";
      var cme_btn_yes = "Oui SVP";
      var home_modal_message = "<p>Vous devez être un membre enregistré pour avoir accès à plus de contenu.</p> <a data-track data-action=\"click\" data-category=\"scrolled-login\" data-label=\"overlay\" href='/fr/auth/login' class='modal-links'>Connexion</a> <br> <p>Pas un membre? Inscrivez-vous GRATUITEMENT maintenant !</p>";
      var home_modal_title = "Vous voulez voir plus ?";
    }

    if($('.loged-out').length && !$('body').hasClass('auth-activate')) {

      // alert($('html').hasClass('fullscreen'));

      $(window).scroll(function() {

        if(($(window).scrollTop() + $(window).height() == $(document).height())) {

          setTimeout(function(e) {
            if(!$('html').hasClass('fullscreen')) {
              var msg = document.createElement("div");
              msg.innerHTML = home_modal_message;

              swal({
                title: home_modal_title,
                content: msg,
                icon: "/assets/images/onco-logo.svg",
                buttons: [btn_thanks, btn_sign_up]
              })
                .then((userAccept) => {
                  if (userAccept) {
                    window.dataLayer = window.dataLayer || [];

                    // Datalayer - Form Homepage & Depositaires
                    dataLayer.push({
                      'event': 'click',
                      'category': 'scrolled-sign-up',
                      'action': 'click',
                      'label': 'overlay'
                    });
                    if (getCookie('site_locale') === 'en'){
                    location.href = "/auth/signup";
                    }else{
                      location.href = "/fr/auth/signup";
                    }
                  }
                });

              $('body').removeClass('has-overlay');

              return false;
            }
          },100);
        }
      });
    }

    $(document).on('click', '[data-auth-protection]', function(e) {
            e.preventDefault();
      e.stopPropagation();

      if($('.loged-out').length) {
        setTimeout(function(e) {
          if (!$('html').hasClass('fullscreen')) {
            var msg = document.createElement("div");

            msg.innerHTML = msg_modal;

            swal({
              title: title,
              content: msg,
              icon: "/assets/images/onco-logo.svg",
              buttons: [ btn_thanks, btn_sign_up]
            })
              .then((userAccept) => {
                if (userAccept) {
                  window.dataLayer = window.dataLayer || [];

                  // Datalayer - Form Homepage & Depositaires
                  dataLayer.push({
                    'event': 'click',
                    'category': 'sign-up',
                    'action': 'click',
                    'label': 'overlay'
                  });

                  if (getCookie('site_locale') === 'en'){
                    location.href = "/auth/signup";
                  }else{
                    location.href = "/fr/auth/signup";
                  }
                }
              });

            $('body')
              .removeClass('has-overlay');

            return false;
          }
        },100);
      }else {

        var msg = document.createElement("div");
        msg.innerHTML = msg_modal;

        swal({
          title: title,
          content: msg,
          icon: "/assets/images/onco-logo.svg",
          buttons: [btn_thanks, btn_sign_up]
        })
          .then((userAccept) => {
            if (userAccept) {
              window.dataLayer = window.dataLayer || [];

              // Datalayer - Form Homepage & Depositaires
              dataLayer.push({
                'event': 'click',
                'category': 'sign-up',
                'action': 'click',
                'label': 'overlay'
              });

              if (getCookie('site_locale') === 'en'){
                location.href = "/auth/signup";
              }else{
                location.href = "/fr/auth/signup";
              }
            }
          });

        $('body')
          .removeClass('has-overlay');

        return false;
      }
    });

    $(document).on('click', '[data-off-cme-protection]', function(e) {
      e.preventDefault();
      e.stopPropagation();
      var msg = document.createElement("div");
      msg.innerHTML = cme_message;
      swal({
        title:cme_title,
        imageUrl: '/assets/images/onco-logo.svg',
        content: msg,
        icon: "/assets/images/onco-logo.svg",
        buttons: [ btn_close , btn_sign_up]
      })
        .then((userAccept) => {
          if (userAccept) {
            window.dataLayer = window.dataLayer || [];

            // Datalayer - Form Homepage & Depositaires
            dataLayer.push({
              'event': 'click',
              'category': 'cme-user-signup',
              'action': 'click',
              'label': 'overlay'
            });

            if (getCookie('site_locale') === 'en'){
              location.href = "/auth/signup";
            }else{
              location.href = "/fr/auth/signup";
            }
          }
        });

      $('body').removeClass('has-overlay');

      return false;

    });

    $(document).on('click', '[data-cme-protection]', function(e) {
      e.preventDefault();
      e.stopPropagation();
      var msg = document.createElement("div");
      msg.innerHTML =  "<p>Would you like us to notify you when it’s available?</p>";


      swal({
        title: cme_title ,
        imageUrl: '/assets/images/onco-logo.svg',
        content: msg,
        icon: "/assets/images/onco-logo.svg",
        buttons: [btn_thanks, cme_btn_yes]
      })
        .then((userAccept) => {
          if (userAccept) {
            window.dataLayer = window.dataLayer || [];

            // Datalayer - Form Homepage & Depositaires
            dataLayer.push({
              'event': 'click',
              'category': 'cme-newsletter-register',
              'action': 'click',
              'label': 'overlay'
            });

            $.get('/user/subCME', function(data) {
              toastr.success('Thank you for your interest! You will be notified when Continuing Medical Education becomes available!' + data);
              window.dataLayer = window.dataLayer || [];
              dataLayer.push({
                'event': 'formSubmission',
                'category': 'subbed-to-cme',
                'action': 'send',
                'label': 'form'
              });

            })
          }
        });

      $('body').removeClass('has-overlay');

      return false;

    });


    $('.auth-newsletter-arrow.news').on('click', function(event) {
      $('input[type=checkbox][name="fields[userWeeklyNewsletter]"]').trigger('click');
    });

    $('.auth-newsletter-arrow.programs').on('click', function(event) {
      $('input[type=checkbox][name="fields[userCMENewsletter]"]').trigger('click');
    });


    /*// On Toggle
    $('.profile-creation .form-toggle-newsletter').on('change', 'input[type="checkbox"]', function(event) {
      if ( $(this).is(':checked') ) {
        $('[name="fields[userOncoNewsletter]"]').val(1);
      } else {
        $('[name="fields[userOncoNewsletter]"]').val(0);
      }
    });

    // On Load
    if ( $('.profile-creation .form-toggle-newsletter input[type="checkbox"]').is(':checked') ) {
      $('[name="fields[userOncoNewsletter]"]').val(1);
    } else if ( $('.profile-creation .form-toggle-newsletter input[type="checkbox"]').length ) {
      $('[name="fields[userOncoNewsletter]"]').val(0);
    }*/

    return true;

  };

  var validateNext = function ($form) {
    var isValid = $form.parsley()._refreshFields().validate({ excluded: "input[type=button], input[type=submit], input[type=reset], input[type=hidden], [disabled], :hidden, [notrequired], .not-requried, .not-required" });


    /*if ( isValid ) {
      $('[data-send-form]').removeClass('is-grey');
    } else {
      $('[data-send-form]').addClass('is-grey');
    }*/

  };

  // Expose public methods & properties
  return {
    init: init
  };

}());
