window.site = (window.site || {});

/**
 * Utilities for Network pages
 * @class Network
 * @static
 */
site.Network = (function Network() {

  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;
  var loaderFaker = null;

  /**
   * Initializes the class.
   * @public
   */
  function getCookie(cName) {
    const name = cName + "=";
    const cDecoded = decodeURIComponent(document.cookie); //to be careful
    const cArr = cDecoded.split('; ');
    let res;
    cArr.forEach(val => {
      if (val.indexOf(name) === 0) res = val.substring(name.length);
    })
    return res
  }
  var init = function () {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;


    // Init();

    if (getCookie('site_locale') === 'en') {
      var swal_title = 'Are you sure ?';
      var swal_yes_btn = 'Yes';
      var swal_no_btn = 'No';
    }else{
      var swal_title = 'Êtes-vous sûr ?';
      var swal_yes_btn = 'Oui';
      var swal_no_btn = 'Non';
    }

    // Expend Dots Settings to unconnect
    $('#formUnconnect .network-card-connect-settings').on('click', function (e) {
      $(this).toggleClass('is-toggled');
    });

    // Unconnect user
    $('#formUnconnect .network-card-connect-settings button').on('click', function (e) {

    });

    // Follow/UnFollow feed/user/group/business...
    $(document).on('click', '.action-follow, [data-action-follow]', function (e) {
      e.preventDefault();

      var $action = $(this);
      var followName = $action.attr('data-follow-name');
      var requestType = $action.attr('data-request-type');

      $.get($action.attr('href'), function (data) {
        /*optional stuff to do after success */
        $action.toggleClass('is-active');

        if ($action.hasClass('is-active')) {

          $action.find('.text').text($action.attr('data-active-text'));
          $('.notifications-alert').fadeOut(355);

          $action.attr('href', $action.attr('data-active-href'));

          if (requestType === 'SIG') {
            if (getCookie('site_locale') === 'en') {
              toastr.success('Awesome! You have joined the Group ' + followName + ', this group can now be found in your <a href="/dashboard">Dashboard</a>.');
            }else{
              toastr.success('Impressionnant! Vous avez rejoint le Groupe ' + followName + ', ce groupe se trouve maintenant dans votre <a href="/fr/dashboard">Tableau de bord</a>.');

            }
            $action.find('.text').text($action.attr('data-active-text'));
            window.dataLayer = window.dataLayer || [];
            dataLayer.push({
              'event': 'formSubmission',
              'category': 'group-joined',
              'action': 'send',
              'label': 'form'
            });
          } else {
            if (getCookie('site_locale') === 'en') {
              toastr.success('Way to go! Keep an eye out for this member\'s posts in your Discover feed.');
            }else{
              toastr.success('Marche à suivre! Gardez un œil sur les messages de ce membre dans votre flux Discover.');
            }

            window.dataLayer = window.dataLayer || [];
            dataLayer.push({
              'event': 'formSubmission',
              'category': 'following-user',
              'action': 'send',
              'label': 'form'
            });
            $action.find('.text').text($action.attr('data-active-text'));
          }

        } else {
          $action.find('.text').text($action.attr('data-innactive-text'));

          $action.attr('href', $action.attr('data-disabled-href'));

          if (requestType === 'SIG') {
            if (getCookie('site_locale') === 'en') {
              toastr.success('You are an integral member of this group, we\'re sorry to see you go. You are welcome to come back anytime!');
            }else{
              toastr.success('Vous faites partie intégrante de ce groupe, nous sommes désolés de vous voir partir. Vous êtes invités à revenir à tout moment!');
            }
            window.dataLayer = window.dataLayer || [];
            dataLayer.push({
              'event': 'formSubmission',
              'category': 'left-group',
              'action': 'send',
              'label': 'form'
            });
            $action.find('.text').text($action.attr('data-innactive-text'));
          } else {
            if (getCookie('site_locale') === 'en') {
              toastr.success('Aw shucks, you are no longer following this member.');
            }else{
              toastr.success('Aw shucks, vous ne suivez plus ce membre.');
            }

            window.dataLayer = window.dataLayer || [];
            dataLayer.push({
              'event': 'formSubmission',
              'category': 'unfollow-member',
              'action': 'send',
              'label': 'form'
            });
            $action.find('.text').text($action.attr('data-innactive-text'));
          }
        }
      });
    });

    // Request Access
    $(document).on('click', '.action-request, [data-action-request]', function (e) {
      e.preventDefault();
      e.stopPropagation();

      var $action = $(this);
      var requestName = $action.attr('data-request-name');

      var requestType = $action.attr('data-request-type');

      if ($action.hasClass('is-active')) {

        if (requestType === 'SIG') {

          var msg = document.createElement("div");
          if (getCookie('site_locale') === 'en') {
            msg.innerHTML = "Are you sure you want to leave the Group " + requestName + "?";
          }else{
            msg.innerHTML = "Êtes-vous sûr de vouloir quitter le groupe" + requestName + "?";
          }


          swal({

            title: swal_title ,
            content: msg,
            icon: "warning",
            buttons: [swal_no_btn, swal_yes_btn]
          })
            .then((userAccept) => {
              if (userAccept) {
                $.get($action.attr('data-active-href'), function (data) {
                  $('.loading-bar', $action).css('width', '0');
                  clearInterval(loaderFaker);
                  $action.removeClass('is-active');
                  $action.removeClass('is-loading');
                  if (getCookie('site_locale') === 'en') {
                    toastr.success('You are an integral member of this group, we\'re sorry to see you go. You are welcome to come back anytime!');
                  }else{
                    toastr.success('Vous faites partie intégrante de ce groupe, nous sommes désolés de vous voir partir. Vous êtes invités à revenir à tout moment!');
                  }
                  window.dataLayer = window.dataLayer || [];
                  dataLayer.push({
                    'event': 'formSubmission',
                    'category': 'left-group',
                    'action': 'send',
                    'label': 'form'
                  });
                });
              } else {
                if (getCookie('site_locale') === 'en') {
                  toastr.success('Thanks for sticking around!');
                }else{
                  toastr.success('Merci d\'être resté!');
                }

                window.dataLayer = window.dataLayer || [];
                dataLayer.push({
                  'event': 'formSubmission',
                  'category': 'cancel-leave-group',
                  'action': 'send',
                  'label': 'form'
                });
              }
            });

        } else {

          $.get($action.attr('data-active-href'), function (data) {
            $('.loading-bar', $action).css('width', '0');
            clearInterval(loaderFaker);
            $action.removeClass('is-active');
            $action.removeClass('is-loading');
            if (getCookie('site_locale') === 'en') {
              toastr.success('Your connection request has been canceled.');
            }else{
              toastr.success('Votre demande de connexion a été annulée.');
            }
            window.dataLayer = window.dataLayer || [];
            dataLayer.push({
              'event': 'formSubmission',
              'category': 'canceled-connection-request',
              'action': 'send',
              'label': 'form'
            });

          });
        }
      } else {

        $action.addClass('is-loading');

        if (!$action.parents().eq(0).find('.action-follow').hasClass('is-active')) {
          $action.parents().eq(0).find('.action-follow').trigger('click');
        }

        var width = 1;
        loaderFaker = setInterval(load, 10);

        function load() {
          if (width >= 100) {
            clearInterval(loaderFaker);
          } else {
            width++;
            $('.loading-bar', $action).css('width', width + '%');
          }
        }

        $.get($action.attr('data-disabled-href'), function (data) {
          /*optional stuff to do after success */
          $('.loading-bar', $action).css('width', '100%');
          setTimeout(function () {
            clearInterval(loaderFaker);
            $action.addClass('is-active');

            if (requestType === 'SIG') {
              if (getCookie('site_locale') === 'en') {
                toastr.success('Your request to join the Group ' + requestName + ' has been sent, a OncoXchange member will review your application within 72 hours if you qualify.');
              }else{
                toastr.success('Votre demande d\'adhésion au Groupe' + requestName + ' a été envoyé, un membre OncoXchange examinera votre candidature dans les 72 heures si vous êtes éligible.');
              }
              window.dataLayer = window.dataLayer || [];
              dataLayer.push({
                'event': 'formSubmission',
                'category': 'requested-group-join',
                'action': 'send',
                'label': 'form'
              });
            } else {
              if (getCookie('site_locale') === 'en') {
              toastr.success('Your connection request has been sent');
              }else{
                toastr.success('Votre demande de connexion a été envoyée');
              }
              window.dataLayer = window.dataLayer || [];
              dataLayer.push({
                'event': 'formSubmission',
                'category': 'requested-user-connection',
                'action': 'send',
                'label': 'form'
              });

            }

          }, 500);
        });
      }

    });

    // Save into list
    $(document).on('click', '.action-save, [data-action-saveToList]', function (e) {
      e.preventDefault();

      var $action = $(this);
      var requestType = $action.attr('data-request-type');

      $.get($action.attr('href'), function (data) {
        /*optional stuff to do after success */
        $action.toggleClass('is-active');

        if ($action.hasClass('is-active')) {

          $action.find('.text').text($action.attr('data-active-text'));
          $('.notifications-alert').fadeOut(355);

          if (requestType == 'Post') {

            if (getCookie('site_locale') === 'en') {
              $action.find('.text').text('PINNED');
            } else {
              $action.find('.text').text('Epinglé');
            }
          }

          $action.attr('href', $action.attr('data-active-href'));
          if (getCookie('site_locale') === 'en') {
          toastr.success('This post has been pinned to your <a href="/dashboard">dashboard</a>. Visit your Dashboard to see all your pinned items.');
          } else {
            toastr.success('Cet article a été épinglé à votre <a href="/fr/dashboard">Tableau de bord</a>. Visitez votre tableau de bord pour voir tous vos éléments épinglés.');
          }
          window.dataLayer = window.dataLayer || [];
          dataLayer.push({
            'event': 'formSubmission',
            'category': 'pinned-' + requestType,
            'action': 'send',
            'label': 'form'
          });
        } else {
          $action.find('.text').text($action.attr('data-innactive-text'));

          if (requestType == 'Post') {
            if (getCookie('site_locale') === 'en') {
              $action.find('.text').text('PIN');
            } else {
              $action.find('.text').text('Epingler');
            }
          }

          $action.attr('href', $action.attr('data-disabled-href'));
          if (getCookie('site_locale') === 'en') {
            toastr.success('This post has been removed from your pinned content.');
          }else{
            toastr.success('Cet article a été supprimé de votre contenu épinglé.');
          }
          window.dataLayer = window.dataLayer || [];
          dataLayer.push({
            'event': 'formSubmission',
            'category': 'remove-pinned-' + requestType,
            'action': 'send',
            'label': 'form'
          });
        }
      });
    });

    // Follow/UnFollow feed/user/group/business...
    $(document).on('click', '[data-action-join]', function (e) {
      e.preventDefault();

      var $action = $(this);
      var sigName = $action.attr('data-sig-name');

      if ($action.hasClass('is-active')) {

        // $.get($action.attr('data-active-href'), function(data) {
        //   $action.removeClass('is-active');
        //   toastr.success('You are an integral member of this group, we\'re sorry to see you go. You are welcome to come back anytime!');
        //   $action.find('.text').text($action.attr('data-innactive-text'));
        //   if ($action.attr('data-page-refresh') === 'refresh') {
        //     location.reload();
        //   }
        // });

        var msg = document.createElement("div");
        if (getCookie('site_locale') === 'en') {
          msg.innerHTML = "Are you sure you want to leave the Group " + sigName + "?";
        }else{
          msg.innerHTML = "Êtes-vous sûr de vouloir quitter le groupe " + sigName + "?";
        }

        swal({
          title: swal_title,
          content: msg,
          icon: "warning",
          buttons: [swal_no_btn, swal_yes_btn]
        })
          .then((userAccept) => {
            if (userAccept) {
              $.get($action.attr('data-active-href'), function (data) {
                $('.loading-bar', $action).css('width', '0');
                clearInterval(loaderFaker);
                $action.removeClass('is-active');
                $action.removeClass('is-loading');
                if (getCookie('site_locale') === 'en') {
                  toastr.success('You are an integral member of this group, we\'re sorry to see you go. You are welcome to come back anytime!');
                }else{
                  toastr.success('Vous faites partie intégrante de ce groupe, nous sommes désolés de vous voir partir. Vous êtes invités à revenir à tout moment!');
                }
                window.dataLayer = window.dataLayer || [];
                dataLayer.push({
                  'event': 'formSubmission',
                  'category': 'left-group',
                  'action': 'send',
                  'label': 'form'
                });
                $action.find('.text').text($action.attr('data-innactive-text'));
                if ($action.attr('data-page-refresh') === 'refresh') {
                  location.reload();
                }
              });
            } else {
              if (getCookie('site_locale') === 'en') {
                toastr.success('Thanks for sticking around!');
              }else{
                toastr.success('Merci d\'être resté!');
              }
              window.dataLayer = window.dataLayer || [];
              dataLayer.push({
                'event': 'formSubmission',
                'category': 'cancel-leave-group',
                'action': 'send',
                'label': 'form'
              });
            }
          });

      } else {

        $.get($action.attr('data-disabled-href'), function (data) {
          $action.addClass('is-active');
          if (getCookie('site_locale') === 'en') {
            toastr.success('Awesome! You have joined the Group ' + sigName + ', this group can now be found in your <a href="/dashboard">Dashboard</a>.');
          }else{
            toastr.success('Impressionnant! Vous avez rejoint le Groupe ' + sigName + ', ce groupe se trouve maintenant dans votre <a href="/fr/dashboard">Tableau de bord</a>.');
          }
          window.dataLayer = window.dataLayer || [];
          dataLayer.push({
            'event': 'formSubmission',
            'category': 'joined-group',
            'action': 'send',
            'label': 'form'
          });
          $action.find('.text').text($action.attr('data-active-text'));

          if ($action.attr('data-page-refresh') === 'refresh') {
            location.reload();
          }
        });

      }
    });

    return true;

  };

  // Expose public methods & properties
  return {
    init: init
  };

}());
