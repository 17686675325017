window.site = (window.site || {});

/**
 * Utilities for Newsfeed components
 * @class Newsfeed
 * @static
 */
site.NewsCarousel = (function NewsCarousel() {

  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;
  var $sliderEl = document.querySelector('.main-carousel.for-featured-articles');

  /**
   * Initializes the class.
   * @public
   */
  var init = function () {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    if ($sliderEl) {
      var $flkty = new Flickity($sliderEl, {
        cellAlign: 'left',
        contain: true,
        wrapAround: true,
        groupCells: 1,
        pageDots: false,
        prevNextButtons: true,
        autoPlay: 10000
      });
    }

    return true;

  };

  // Expose public methods & properties
  return {
    init: init
  };

}());
