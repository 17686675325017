window.site = (window.site || {});

/**
 * Utilities for SystemNotifications components
 * @class SystemNotifications
 * @static
 */
site.CropperJS = (function CropperJS($) {

  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  var $body = document.querySelector('body');
  var $modalCropper = document.querySelector('.overlay-modal[js-cropper-modal]');
  var $inputBanner = document.querySelector('input#upload.cropper-uploader');
  var $inputPhoto = document.querySelector('input#userPhoto');
  var $modalCanvas = document.querySelector('.cropper-canvas');
  var $cropper = null;
  var $modalCropBtn = document.querySelector('.site-btn.btn-crop');

  /**
   * Initializes the class.
   * @public
   */
  var init = function() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    
    if ($inputBanner) {

      $inputBanner.addEventListener('change', (e) => {

        const inputFile = $inputBanner.files[0];
        if ($modalCropper && inputFile) {
          $modalCropper.classList.add('is-active');
          $body.classList.add('has-overlay');

          var reader = new FileReader();

          reader.onload = function(event) {

            $modalCanvas.onload = function() {
              if ($cropper) {
                $cropper.destroy();
              }

              $cropper = new Cropper($modalCanvas, {
                aspectRatio: 16 / 9,
                viewMode: 1,
                minContainerWidth: $modalCanvas.clientWidth,
                minContainerHeight: $modalCanvas.clientHeight,
                rotatable: true,
                scalable: true,
                checkOrientation: true,
                crop(event) {
                                                                                                                                              },
              });

              $modalCropBtn.addEventListener('click', () => {
                document.querySelector('input[name="fields[userBannerImage]"]').value = $cropper.getCroppedCanvas().toDataURL();
                const previewBanner = document.querySelector('.profile-box-header.input-banner[action-image-previewer]');

                previewBanner.style.backgroundImage = 'url(' + $cropper.getCroppedCanvas().toDataURL() + ')';
                previewBanner.style.backgroundSize = 'cover';
                previewBanner.style.backgroundPosition = 'center';
                $modalCropper.classList.toggle('is-active');
                document.querySelector('.profile-form.cropper-form').classList.add('is-active-banner');
                $cropper.destroy();
              });
            };

            $modalCanvas.src = event.target.result;
          };
          reader.readAsDataURL(inputFile);
        }
      });
    }

    if ($inputPhoto) {

      $inputPhoto.addEventListener('change', (e) => {

        const inputFile = $inputPhoto.files[0];
        if ($modalCropper && inputFile) {
          $modalCropper.classList.add('is-active');
          $body.classList.add('has-overlay');

          var reader = new FileReader();

          reader.onload = function(event) {

            $modalCanvas.onload = function() {
              if ($cropper) {
                $cropper.destroy();
              }

              $cropper = new Cropper($modalCanvas, {
                aspectRatio: 1 / 1,
                viewMode: 1,
                minContainerWidth: $modalCanvas.clientWidth,
                minContainerHeight: $modalCanvas.clientHeight,
                rotatable: true,
                scalable: true,
                checkOrientation: true,
                crop(event) {
                                                                                                                                              },
              });

              $modalCropBtn.addEventListener('click', () => {
                document.querySelector('input[type="hidden"][name="fields[userPhoto]"]').value = $cropper.getCroppedCanvas().toDataURL();
                const previewBanner = document.querySelector('.profile-box-img.input-avatar[action-image-previewer]');

                previewBanner.style.backgroundImage = 'url(' + $cropper.getCroppedCanvas().toDataURL() + ')';
                previewBanner.style.backgroundSize = 'cover';
                previewBanner.style.backgroundPosition = 'center';
                $modalCropper.classList.toggle('is-active');

                document.querySelector('.profile-form.cropper-form').classList.add('is-active-pfp');
                $cropper.destroy();
              });
            };


            $modalCanvas.src = event.target.result;
          };
          reader.readAsDataURL(inputFile);
        }
      });
    }

  };

  // Expose public methods & properties
  return {
    init: init
  };

}(jQuery));
