window.site = (window.site || {});

/**
 * Utilities for Auth components
 * @class Auth
 * @static
 */
site.OverlayModal = (function OverlayModal() {

    'use strict';

    /**
     * Has the class been initialized?
     * @private
     */
    var inited = false;
    var $modal = $('.overlay-modal');
    var $modalTrigger = $('a[data-overlay]');
  var $closeBtn = $('.overlay-container .close-btn');
  var $cancelBtn = $('.overlay-container .btn-cancel');

    var currentPostID = null;

    /**
     * Initializes the class.
     * @public
     */
    var init = function () {

      // Abort if already initialized
      if (inited) {
        return false;
      }

      inited = true;

      $.each( $('.overlay-modal select[multiple]'), function(index, val) {
        $(this).select2({
          placeholder: $(this).attr('placeholder'),
          maximumSelectionLength: 3
        });
      });

      $('body').on('click', 'a[data-overlay]', function(e) {
        e.preventDefault();

        if ($(this).attr('data-share-post') === 'own') {
          $('[data-hide-for-own-post]').hide();
        } else {
          $('[data-hide-for-own-post]').show();
        }

        var shareID = $(this).attr('data-share-id');

        
        if (shareID) {
          $('[data-overlay="share-post"] [name="entryId"]').val(shareID);
          currentPostID = shareID;
        }

        openOverlay($(this).data('overlay'));
      });

      $modal.on('click', function(e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.target !== this)
          return;
        hideOverlay();
      });

      $closeBtn.on('click', function (e) {
          e.preventDefault();
          e.stopPropagation();
          hideOverlay();
      });

      $cancelBtn.on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();
        hideOverlay();
      });

      $modal.on('click', 'a[data-content]', function(e) {
        e.preventDefault();
        e.stopPropagation();

        $modal.find('a[data-content]').removeClass('is-active');
        $(this).addClass('is-active');

        $modal.find('div[data-content]').hide();
        $modal.find('div[data-content="'+$(this).attr('data-content')+'"]').show();
      });

      $('.overlay-modal .overlay-content').on('click', function(e) {
        e.preventDefault();
        e.stopPropagation();
      });

      $('.overlay-modal form:not("#inviteColleagueForm")').on('click', '[type="submit"]', function(e) {

        var $button = $(this);
        var $form = $button.parents('form');

        // Disable button
        e.preventDefault();
        $button.prop('disabled', true);
        $button.addClass('loading');

        if ( ! $form.parsley({
          classHandler: function (el) {
            return el.$element.parent();
          },
          errorsContainer: function(el) {
            return el.$element.closest('.input-label');
          }
        }).validate() ) {
          return false;
        }

        // Send form data through an ajax post
        var data = new FormData($form[0]);
            data.append('site_csrf', site.App.config('csrf'));

        var url = $form.find('[name="action"]').val();

        var request = $.ajax({
          url: url,
          type: 'POST',
          data: data,
          cache: false,
          contentType: false,
          processData: false,
        });

          // Request done
        request.done(function(response) {

          if (response.success && response.success === true) {

            toastr.success('This article has been shared.');
            window.dataLayer = window.dataLayer || [];
            dataLayer.push({
              'event': 'formSubmission',
              'category': 'shared-article',
              'action': 'send',
              'label': 'form'
            });

            hideOverlay();

            // Return success
            return true;

          } else if (response.errors) {


            // Return failure
            return false;

          } else {



          }

        });

        // Request failed
        request.fail(function(jqXHR, textStatus) {
          alert('Erreur serveur: ' + textStatus);
        });

        // Enable button after 3 seconds
        setTimeout(function() {
          $button.prop('disabled', false);
          $button.removeClass('error success loading');
        }, 3000);

      });

      $('.overlay-modal').on('click', '[data-share-on-my-feed]', function(e) {
        e.preventDefault();
        e.stopPropagation();
        if (currentPostID) {
                    $.get('/actions/site_Feed/sharePostOnMyFeed?id=' + currentPostID, function(data) {
            toastr.success('This article has been shared.');
            window.dataLayer = window.dataLayer || [];
            dataLayer.push({
              'event': 'formSubmission',
              'category': 'shared-article',
              'action': 'send',
              'label': 'form'
            });
            hideOverlay();
          });
        }
      });

      return true;

    };

    var openOverlay = function(value) {
      $modal.find('a[data-content]').removeClass('is-active');
      $modal.find('div[data-content]').hide();
      $('body').addClass('has-overlay');
      $('.overlay-modal[data-overlay="'+ value+'"]').addClass('is-active');
      setTimeout(function()    {
        $('.overlay-modal[data-overlay="'+ value+'"]').find('input[name="fields[formName]"]').focus();
      }, 1200);
    };

    var hideOverlay = function() {
      $('body').removeClass('has-overlay');
      $modal.removeClass('is-active');
    };

    // Expose public methods & properties
    return {
      init: init,
      openOverlay: openOverlay
    };

}());
