window.site = (window.site || {});

/**
 * Utilities for article posting page.
 * @class Article
 * @static
 */
site.Article = (function Article($) {

  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;
  var loaderFaker = null;

  /**
   * jQuery elements.
   * @private
   */
  var $form = $('[data-form="article"]');

  /**
   * Initializes the class.
   * @public
   */
  var init = function() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    $('.article-details-card .post-header').on('click', function(e){
      
      var $videoContainer = $(this).find('.video-container');


      if($videoContainer.length) {
        $(this).addClass('show-video').find('.header-content').hide();
      }

    });

    if ($form.length) {

      $('#SIGs-primary').select2({
        placeholder: $('#SIGs-primary').attr('placeholder'),
        maximumSelectionLength: 1
      });

      $('#SIGs').select2({
        placeholder: $('#SIGs').attr('placeholder'),
        maximumSelectionLength: 2
      });

      $('#tags').select2({
        placeholder: $('#tags').attr('placeholder'),
        maximumSelectionLength: 3
      });

      initForm();
    }

    return true;

  };

  /**
   * Initialize job form.
   * @private
   */
  var initForm = function() {

    
    var $error   = $('[data-alert-error]', $form);
    var $success = $('[data-alert-success]', $form);
    var $button  = $('[data-form-button]', $form);
    var $textarea  = $('textarea#content', $form);

    /**
     * Submit event
     */
    $form.on('submit', function(e) {

      $('.alert').remove();

      // Disable button
      e.preventDefault();
      $button.prop('disabled', true);
      $button.addClass('loading');

      if ( ! $form.parsley({
        classHandler: function (el) {
          return el.$element.parent();
        },
        errorsContainer: function(el) {
          return el.$element.closest('.input-label');
        }
      }).validate() ) {
        return false;
      }

      // Send form data through an ajax post
      var data = new FormData($form[0]);
          data.append('site_csrf', site.App.config('csrf'));

      
      var url = '/form/saveArticle';

      if($form.data('article-type') === 'edit'){
        url = '/form/editArticle';
      }

      
      var request = $.ajax({
        url: url,
        type: 'POST',
        data: data,
        cache: false,
        contentType: false,
        processData: false,
      });


      var $action = $('.js-create-submit');

      if ($action.hasClass('is-active') || $action.hasClass('is-loading')) {
        return;
      }

      $action.addClass('is-loading').prop('disabled', true);

      var width = 1;
      loaderFaker = setInterval(load, 10);

      function load() {
        if (width >= 100) {
          clearInterval(loaderFaker);
        } else {
          width++;
          $('.loading-bar', $action).css('width', width +  '%');
        }
      }

        // Request done
      request.done(function(response) {

        // alert(response.draft);

        if (response.success && response.success === true) {

        $('.loading-bar', $action).css('width', '100%');
        setTimeout(function () {
          clearInterval(loaderFaker);
          $action.toggleClass('is-active');
        }, 500);

          // Trigger the success event
          if (response.draft) {
            $form.trigger('form.success').before(`
              <section class="form-section">
                <div class="input-row">
                  <div class="auth-form-notifications"><p class="success">Success! Your article has been submitted.</p></div>
                </div>
              </section>`);
            $('.parsley-success').removeClass('parsley-success');
          } else {
            $form.trigger('form.success').before(`
              <section class="form-section">
                <div class="input-row">
                  <div class="auth-form-notifications"><p class="success">Your article has been saved as a draft.</p></div>
                </div>
              </section>`);
            $('.parsley-success').removeClass('parsley-success');
          }

          // Reset form after 3 seconds
          setTimeout(function() {

            if($form.data('article-type') === 'article') {
              $form.trigger('form.reset');
            }

            $('.auth-form-notifications').remove();

            if (response.draft) {
              location.href = '/SIG/'+response.sig+'/'+response.slug;
            } else {
              location.href = '/dashboard';
            }

          }, 3000);

          // Return success
          return true;

        } else if (response.errors) {

            $action.removeClass('is-loading').prop('disabled', false);
          // Trigger the error event
          $form.trigger('form.error', response.errors);

          var errorMessages = '';
          $.each(response.errors, function(index, value){
            errorMessages += '<li>' + value + '</li>';
          });

          // $form.before('<div class="auth-form-notifications success"><p>' + errorMessages + '</p></div>');
          $form.before(`
            <section class="form-section">
              <div class="input-row">
                <div class="auth-form-notifications"><p class="success">${errorMessages}</p></div>
              </div>
            </section>`);

          // Return failure
          return false;

        } else {

          // Return failure
            $action.removeClass('is-loading').prop('disabled', false);
          alert('Erreur serveur: Réponse invalide');
          return false;

        }

      });

      // Request failed
      request.fail(function(jqXHR, textStatus) {
        alert('Erreur serveur: ' + textStatus);
      });

      // Enable button after 3 seconds
      setTimeout(function() {
        $button.prop('disabled', false);
        $button.removeClass('error success loading');
      }, 3000);

    });

    /**
     * Error event
     */
    $form.on('form.error', function(e, errors) {

      // Show the error alert
      $('p', $error).text('');

      for (var error in errors) {
        $('p', $error).append(errors[error] + "<br>");
      }

      $error.show();
      $success.hide();
      $button.addClass('error');
      $button.removeClass('loading');

      // Scroll to top of the form
      $('html, body').animate({ scrollTop: $form.offset().top - 105 }, 500);

    });

    /**
     * Success event
     */
    $form.on('form.success', function() {

      // Track success event
      // site.Track.event('Job Post', 'Submit', 'Success');

      // Show the success alert
      $error.hide();
      $success.show();

      $button.addClass('success');
      $button.removeClass('loading');

      // Scroll to top of the form
      $('html, body').animate({ scrollTop: $form.offset().top - 105 }, 500);

    });

    /**
     * Reset event
     */
    $form.on('form.reset', function() {

      $form.trigger('reset');
      $error.hide();
      $success.hide();
      $button.removeClass('error success loading');
      $button.prop('disabled', false);
      $textarea.siblings('.redactor-layer').text('');

      location.reload();

    });

  };

  // Expose public methods & properties
  return {
    init: init
  };

}(jQuery));
