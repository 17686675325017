window.site = (window.site || {});

/**
 * Preview uploaded image on user dashboart without uploading
 * @class UploadedImagePreview
 * @static
 */
site.UploadedImagePreview = (function UploadedImagePreview($) {

  
  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false,
      containerSelector = '[action-image-previewer]',
      inputSelector = '[action-preview-img]',
      bannerContainerSelector = '.input-banner',
      bannerSelector = '.input-banner [name="fields[userBannerImage]"]';

  /**
   * Initializes the class.
   * @public
   */
  var init = function() {

    // Abort if already initialized
    if (inited || !$('.input-avatar [name="userPhoto"]').length && !$('#uploadPostImage').length) {
      return false;
    }

    
    inited = true;

    // Initiate image preview

    $('[action-clear-preview]').on('click', function() {
      $(this).parents('.input-fields').find('input[name="postAttachedImage"]').val('');
      $(this).parents('.image-preview-holder').removeClass('is-active');
    });

    // var choose = document.getElementById('uploadPostImage');

    var container = document.getElementById('input-avatar');

    $.each($('[action-preview-img]'), function(){
      var choose = this;

      FileAPI.event.on(choose, 'change', function (evt){
                var currentInput = $(this);
        
        var files = FileAPI.getFiles(evt); // Retrieve file list

        FileAPI.filterFiles(files, function (file, info/**Object*/){

          if( /^image/.test(file.type) ){
            return  info.width >= 320 && info.height >= 240;
          }
          return  false;
        }, function (files/**Array*/, rejected/**Array*/){
          var passedFile = files.length ? files : rejected;

          if( passedFile.length ){
            // Make preview 100x100
            FileAPI.each(passedFile, function (file){
                            FileAPI.Image(file).preview(110).get(function (err, img){
                                var canvas = document.getElementsByTagName('canvas');
                if(canvas.length) {
                  canvas[0].parentNode.removeChild(canvas[0]);
                }

                loadImage.parseMetaData(file, function(data) {
                  var orientation = true;
                  /*if (data.exif) {
                    orientation = data.exif.get('Orientation');
                  }*/

                  
                  loadImage(
                    file,
                    function (image) {
                      var base64data = image.toDataURL('image/jpeg');

                                                                  
                      currentInput.closest('[action-image-parent]').find('[action-image-holder]').addClass('is-active');
                      currentInput.closest('[action-image-parent]').find('[action-image-previewer]').css('background-image', 'url('+base64data+')').css('background-size', 'cover').css('background-position', 'center');
                    },
                    { canvas:true, orientation:true }
                  );
                });
              });
            });

            // Uploading Files
            /*FileAPI.upload({
                url: './ctrl.php',
                files: { images: files },
                progress: function (evt){ /!* ... *!/ },
                complete: function (err, xhr){ /!* ... *!/ }
            });*/
          }
        });
      });
    })



    return true;

  };

  /**
   * Handle trigger submit button
   * @public
   */
  var imagePreview = function() {

    // $(bannerSelector).change(function(){
    //     readURLBanner(this);
    // });

    $(inputSelector).change(function(){
            readURL(this);
    });

  };

  /**
   * Image preview function
   * @public
   */
  var readURL = function(input) {

    
    if (input.files && input.files[0]) {
            var reader = new FileReader();

      reader.onload = function(e) {

        
        // $(containerSelector).css('background-image', 'url('+e.target.result+')').css('background-size', 'cover').css('background-position', 'center');
      }

      reader.readAsDataURL(input.files[0]);

        var choose = document.getElementById('userPhoto');
    }
  }

  /**
   * Image preview function
   * @public
   */
  var readURLBanner = function(input) {

    if (input.files && input.files[0]) {
      var reader = new FileReader();

      reader.onload = function(e) {
        $(bannerContainerSelector).css('background-image', 'url('+e.target.result+')').css('background-size', 'cover').css('background-position', 'center');
      }

      reader.readAsDataURL(input.files[0]);
    }
  }

  // Expose public methods & properties
  return {
    init: init
  };

}(jQuery));
