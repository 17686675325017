/*
$(function(){

  /!* VIDEO COVER *!/

  $(window).resize(function(){


    var video = $("#video");
    var videoWidth = video.width();
    var videoHeight = video.height();

    if( videoWidth < $(window).width() ){
      video.addClass("coverX");
      video.removeClass("coverY");
    }

    if( videoHeight < $(window).height() ){
      video.addClass("coverY");
      video.removeClass("coverX");
    }

  }).resize();

  /!* FAKE <a href="#"></a> *!/

  $("[data-fake-href]").click(function(){
    window.top.location.href = $(this).attr("data-fake-href");
    return false;
  });

  /!* FULLSCREEN MODE ON/OFF *!/

  $(window).bind('webkitfullscreenchange mozfullscreenchange fullscreenchange', function(e) {
    var state = document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen;
    var event = state ? 'FullscreenOn' : 'FullscreenOff';

    if( event == "FullscreenOn" ){
      $("html").addClass("fullscreen");
      if( $("#volume").hasClass("mute") ){ return; }
      $("#video")[0].volume = 1;
    }else{
      $("html").removeClass("fullscreen");
      $("#video")[0].volume = 0;
    }
  });

  $("#toggleFullScreen").click(function() {
    if( !(document.fullscreen || document.mozFullScreen || document.webkitIsFullScreen || document.msFullscreenElement) ){
      goFullScreen();
    }else{
      closeFullScreen();
    }
    return false;
  });

  /!* PLAY/PAUSE FUNCTIONNALITY *!/

  $(document).bind('keyup', function(e) {
    if(e.keyCode == 32) { // spacebar
      // Prevent play/pause if someone is typing in a box
      if( $(":focus").length > 0 ){ return false; }
      $("#pause").click();
    }
  });

  $("#vignette, #pause").click(function(e){

    if( $(".window:visible").length > 0 && $(e.target).is("#vignette") ){
      $(".btn.toggleWindow").removeClass("active");
      return false;
    }

    if( $("#video")[0].paused ){
      $("#video")[0].play();
      $("#play").removeClass("visible");
      $("#pause").removeClass("playing");
    }else{
      $("#video")[0].pause();
      $("#play").addClass("visible");
      $("#overlay").mousemove();
      $("#pause").addClass("playing");
    }
  });

  /!* MUTE/UNMUTE TOGGLE *!/

  $("#volume").click(function(){
    if( $("#video")[0].volume == 0 ){
      $(this).removeClass("mute");
      $("#video")[0].volume = 1
    }else{
      $(this).addClass("mute");
      $("#video")[0].volume = 0
    }
  });

  /!* RESTART VIDEO FROM BEGINING *!/

  $("#reset").click(function(){
    $("#video")[0].currentTime = 0;
  });

  /!* PROGRESS BAR *!/

  $(document).bind('keydown', function(e) {

    $("#time").attr("data-seek-time", "");

    if(e.keyCode == 37) { // left
      $("#video")[0].currentTime = $("#video")[0].currentTime-5;
      updateProgress();
    }else if(e.keyCode == 39) { // right
      $("#video")[0].currentTime = $("#video")[0].currentTime+5;
      updateProgress();
    }
  });

  $("#progressBar").hover(function(){
    $(".holder.bottom").addClass("adjust");
  },function(){
    $(".holder.bottom").removeClass("adjust");
  });

  $(document).mouseup(function(e) { //Default mouse Position

    if(!down){return false;}

    var seekPercent = (e.pageX/$("#progressBar").width())*100;

    var newTime = ($("#video")[0].duration*seekPercent)/100;

    $("#video")[0].currentTime = newTime;

    $("#buffer").width(0).css({left:0});

    updateProgress();
  });

  var down = false;
  $("#progressBar").mousedown(function() {
    down = true;
  });
  $(document).mouseup(function() {
    down = false;
  });

  $(document).bind("mousemove mousedown",function(e) { //Default mouse Position
    var elem = "#progressBar";
    if(down) {
      var seekPercent = (e.pageX/$(elem).width())*100;
      var newTime = ($("#video")[0].duration*seekPercent)/100;
      $("#progress").width(seekPercent+"%");
      $("#time").text(formatTime(newTime));
    }
  });

  function updateProgress(){

    if(down){ //Â Prevent update while seeking
      return false;
    }

    var duration = formatTime($("#video")[0].duration);
    $("#duration").text(duration);

    var currentTime = formatTime($("#video")[0].currentTime);
    var progress = ($("#video")[0].currentTime/$("#video")[0].duration)*100;

    $("#progress").width(progress+"%");

    for (i = 0; i < $("#video")[0].buffered.length; i++) {

      if( $("#video")[0].buffered.end(i) <  $("#video")[0].currentTime ){
        continue;
      }else if( $("#video")[0].buffered.start(i) >  $("#video")[0].currentTime ){
        continue;
      }

      $("#buffer").width(((($("#video")[0].buffered.end(i)-$("#video")[0].buffered.start(i)) / $("#video")[0].duration)*100)+"%");
      $("#buffer").css({left: (($("#video")[0].buffered.start(i) / $("#video")[0].duration)*100)+"%"});
    }

    $("#time").text(currentTime);
  }

  $(window).load(function(){

    setInterval(function(){
      updateProgress();
    }, 1000);

  });

  /!* WINDOWS MODULES *!/

  $(".btn.toggleWindow").click(function(){
    $(".btn.toggleWindow").not(this).removeClass("active");
    $(this).toggleClass("active");
  });

  /!* OVERLAY FADEIN/FADEOUT *!/

  // Show overlay when hitting a key
  $(document).bind('keyup',function(){
    $("#overlay").mousemove();
  });

  var fadeout = null;
  $("#overlay").bind("mousemove touchmove",function() {
    var elem = this;
    $(elem).addClass("visible");
    if (fadeout != null) {
      clearTimeout(fadeout);
    }
    fadeout = setTimeout(function(){

      if( $(".window:visible").length > 0 ){
        return false;
      }

      $(elem).removeClass("visible");
    },5000);

    return false;
  });

  /!* ONPAGE LOAD *!/

  // Mute the video on pageload
  $("#video")[0].volume = 0;

  // IF THE PLAYER IS STANDALONE MODE
  if( !inIframe() ){
    $("html").addClass("fullscreen");
    if( $("#volume").hasClass("mute") ){ return; }
    $("#video")[0].volume = 1;

    $("#volume, #toggleFullScreen").closest(".group").hide();
  }

});

function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}
*/
