window.site = (window.site || {});

/**
 * Utilities for LiveNotificationsCounter components
 * @class LiveNotificationsCounter
 * @static
 */
site.LiveNotificationsCounter = (function LiveNotificationsCounter() {

  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;
  var $notificationCounter = $('#notification-counter-wrapper');

  /**
   * Initializes the class.
   * @public
   */
  var init = function () {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    
    // Init();

    // onPageReadyRefreshCounter();

    // Also always check every 30 secconds
    var checkInterval = setInterval(function() {
      //       // onPageReadyRefreshCounter();
    }, 10000);

    return true;

  };

  var onPageReadyRefreshCounter = function () {

    // Refresh Counter component
    // By Loading new state of _app/_partials/notification-counter.twig
    // $notificationCounter.load("/_app/_partials/system-notifications/notification-badge.twig");

  };

  // Expose public methods & properties
  return {
    init: init,
    // 'refreshCounter': onPageReadyRefreshCounter
  };

}());
