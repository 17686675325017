window.site = (window.site || {});

/**
 * Utilities for Search bar - Algolia
 * @class Search
 * @static
 */
site.Search = (function Search() {

  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  // Global
  var _algolia = null;
  var _trans = {
    'searchNewsfeed': 'Newsfeed',
    'searchUsers': 'Users',
    'searchSIGs': 'Groups',
  }

  // Components
  var $searchBarWrapper = $('#search-wrapper');
  var $searchBtn = $('.search-btn');
  var $searchBar = $('#search');
  var $resultsBoxContainer = $('.search-results');
  var $resultsBox = $('.search-results-wrap');

  /**
   * Initializes the class.
   * @public
   */
  var init = function () {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    function toggleOverflow(e) {
      if (e.propertyName !== 'max-height') return;

      if (this.classList.contains('is-active')) {
        this.style.overflow = 'visible';
      }
    }

    // Init();
    
    if (!$searchBarWrapper.length) {
      return;
    }

    $searchBarWrapper.addClass('is-hidden');
    $searchBarWrapper[0].addEventListener('transitionend', toggleOverflow)

    // Init algolia search Object
    // _algolia = algoliasearch(site.App.config('algolia').app_id, site.App.config('algolia').admin_api);

    /**
     * On Search btn click
     */
    $searchBtn.on('click', function(event) {
      event.preventDefault();
      event.stopPropagation();

      if ($searchBarWrapper.hasClass('is-active')) {
        $('.search-form-input').blur();
        $searchBarWrapper[0].style.overflow = 'hidden';
        $searchBarWrapper.removeClass('is-active');
        $searchBtn.removeClass('is-active');
      } else {
        $searchBarWrapper.addClass('is-active');
        $searchBtn.addClass('is-active');

        setTimeout(function () {
          $('.search-form-input').focus();
        }, 355);
      }
    });

    // Close Search on body click
    $('body, html').on('click', function () {
      // $searchBarWrapper[0].style.overflow = 'hidden';
      // $searchBarWrapper.removeClass('is-active');
      // $searchBtn.removeClass('is-active');
    });

    $('.search-form').on('click', function(event) {
      event.stopPropagation();
    });

    /**
     * On Change Search type
     */
    $searchBarWrapper.on('change', '[name="type"]', function(event) {
      $searchBar.focus().trigger('keyup');
    });

    /**
     * On input keydown - search query
     */
    $searchBar.on('keyup', function(event) {

      var query = $(this).val();
      var type = $(this).parents('form').find('[name="type"] option:selected').val();

      // 
      if ( $(this).val() ) {
        searchFor( query, type );
      } else {
        $resultsBoxContainer.removeClass('is-visible');
      }
    });

    return true;

  };

  /**
   * Prepare query and search
   * @query {string}  the query string
   */
  var searchFor = function (query, type) {

    
    // The Query object
    // Search multiples indexes
    var queries = [];

    if (type === 'all' || type === 'posts' || type === 'articles') {
      queries.push({
        indexName: 'searchNewsfeed',
        query: query,
        params: {
          hitsPerPage: 10
        }
      });
    }

    if (type === 'all' || type === 'users') {
      queries.push({
        indexName: 'searchUsers',
        query: query,
        params: {
          hitsPerPage: 10
        }
      });
      queries.push({
        indexName: 'searchSIGs',
        query: query,
        params: {
          hitsPerPage: 10
        }
      });
    }

    // perform queries with algolia
    // Call back function = searchCallback(err, content);
    // _algolia.search(queries, searchCallback);

  };

  /**
   * Show search results if any
   * @err {string}   Error msg
   * @content {obj}  Algolia results object
   */
  var searchCallback = function (err, content) {

    // Error handling
    if (err) {
      console.error(err);
      return;
    }

    
    var results = content.results;

    $resultsBox.empty();

    // Loop all results and show them in the search-box-results
    $.each( results, function(index, val) {
      var hitsIndex = val.index;
      var hitsResults = val.hits;

      if ( hitsResults.length ) {

        $resultsBox.append('<h6 class="search-results-heading">' + _trans[hitsIndex] + '</h6>');
        var $zone = $('<ul class="search-results-hits"></ul>').appendTo($resultsBox);

        // 
        $.each( hitsResults, function(index, val) {
          var id = val.id ? val.id : '';
          var slug = val.slug;
          var username = val.username;
          var label = val._highlightResult.title ? val._highlightResult.title.value : val._highlightResult.name.value;
          var text = val._highlightResult.postText ? '<p>' + val._highlightResult.postText.value + '</p>' : '';

          var thumbnail = '/assets/images/post-image-ph.jpg';

          if (hitsIndex === 'searchUsers') {
            var thumbnail = '/cpresources/social/images/defaultuser.svg';
          }

          if (hitsIndex === 'searchSIGs') {
            var thumbnail = '/assets/images/feedly-ph-sigs.jpg';
          }

          if (val.postEmbedThumbnail) {
            thumbnail = (hitsIndex === 'searchUsers' || hitsIndex === 'searchSIGs') ? val.postEmbedThumbnail.replace('https://medcoxchange.hff.io/', '/') : val.postEmbedThumbnail;
          }

          if (text && text.length >= 200) {
            text = text.substr(0, 200) + '...';
          }

          var hitUrl = (hitsIndex === 'searchUsers' || hitsIndex === 'searchSIGs') ? '/profile/' + id + '' : '/view/' + id + '/' + slug + '';

          $zone.append('<li class="search-results-hit"><a class="inner" href="' + hitUrl + '"><img src="' + thumbnail + '" /><div class="hit-content"><strong>' + label + '</strong>' + text + '</div></a></li>');
        });
      }
    });

    // If there is results for the query, we show the results-box
    // else, we hide it
    if ( ! getNbHits(results) ) {
      $resultsBoxContainer.removeClass('is-visible');
    } else {
      $resultsBoxContainer.addClass('is-visible');
    }
  };

  /**
   * Return query hits (nb results)
   * @param {obj}   results   The results object from Algolia
   * @return {int}            Numbers of Hits/results
   */
  var getNbHits = function (results) {
    var nb = 0;

    $.each( results, function(index, val) {
      nb += val.nbHits;
    });

    return nb;
  };

  // Expose public methods & properties
  return {
    init: init
  };

}());
