window.site = (window.site || {});

/**
 * Utilities for ContentCreatorBox component
 * @class ContentCreatorBox
 * @static
 */
site.ContentCreatorBox = (function ContentCreatorBox() {

  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  var $creator = $('#content-creator-box');
  var $creatorNavItems = $('.content-creator-tab[data-tab]', $creator);
  var $creatorForms = $('form[data-tab]', $creator);

  /**
   * Initializes the class.
   * @public
   */
  var init = function () {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    // Init();
    
    // hideAllForms();
    // activateSelectedForm();

    $creatorNavItems.on('click', function(event) {
      event.preventDefault();


      $('div[data-tab="' + $(this).data('tab') + '"]').css('display','flex');

      // $creatorNavItems.removeClass('is-active');
      // $(this).addClass('is-active');

      // activateSelectedForm();
    });

    // Send Comment Form AJAX
    // Newly scoped to only accept the regular post form.
    // The Image post form will be handled differently
    $(document).on('submit', '[data-content-creator-box] form.regular-form', function(e) {
        e.preventDefault();

        
        var $form = $(this);
        var $formSubmit = $(this).find('[type="submit"]');
        var data = $form.serialize();
        var bindingTo = $form.parents('[data-content-creator-box]').attr('data-content-creator-box');
        var $ajaxZone = $('[data-ajax-result="' + bindingTo + '"]');

        var isSbumitting = false;

        if ( ! $formSubmit.prop('disabled') && ! isSbumitting ) {

          // isSbumitting = true;
          $formSubmit.prop('disabled', true);
          $ajaxZone.prepend($(`<div class="loading-effect" >
            <div class="sk-folding-cube">
              <div class="sk-cube1 sk-cube"></div>
              <div class="sk-cube2 sk-cube"></div>
              <div class="sk-cube4 sk-cube"></div>
              <div class="sk-cube3 sk-cube"></div>
            </div>
          </div>`));

          $.ajax({
              method: 'POST',
              url: data.action,
              data: data,
              headers: {'Content-Type': 'application/x-www-form-urlencoded'}
          })
          .success(function(data) {
              
              // Clear out the text box
              $form[0].reset();

              // Add the new post to FEED
              var $newPostHtml = $(data).find('[data-ajax-result="' + bindingTo + '"] .newsfeed-post-card:first-child');

              
              $ajaxZone.prepend($newPostHtml);
              $formSubmit.prop('disabled', false);
              $ajaxZone.find('.loading-effect').remove();
              $ajaxZone.find('[data-empty-post-notification]').remove();

              toastr.success('You rock, thanks for posting! Your post has been shared.');
              window.dataLayer = window.dataLayer || [];
              dataLayer.push({
                'event': 'formSubmission',
                'category': 'posted-post',
                'action': 'send',
                'label': 'form'
              });
              // isSbumitting = false;
          })
          .error(function(data) {
                            $formSubmit.prop('disabled', false);
              $ajaxZone.find('.loading-effect').remove();
              // isSbumitting = false;
          });

        }
    });


    $('.js-limit textarea').on('keyup keydown', function(){
      characterCount($(this));
    });


    $(document).on('click', '[data-member-protection]', function(e) {
      e.preventDefault();
      e.stopPropagation();

      swal({
        title: "You must join the Group",
        imageUrl: '/assets/images/onco-logo.svg',
        text: "You must be part of the Group to be able to post on their wall.",
        icon: "warning",
        buttons: ["Ok"]
      });

      return false;

    });

    return true;
  };

  /*var hideAllForms = function () {

    $creatorForms.each(function(index, el) {
      $(el).addClass('is-hidden');
    });
  };*/

  /*var activateSelectedForm = function () {

    var selectedForm = $('.content-creator-tab[data-tab].is-active', $creator).attr('data-tab');

    $('div[data-tab="' + selectedForm + '"]').addClass('is-active');
  };
*/
  var characterCount = function(textarea) {

    var reg = /(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?/;
    var textValue = textarea.val();


    var postCommentBtn = $('[action-post-comment]');

    if (textarea.val().length >= 30) {
      postCommentBtn.removeClass('is-disabled');
    } else {
      postCommentBtn.addClass('is-disabled');
    }

    postCommentBtn.prop('disabled', textarea.val().length <= 30);

    if(reg.test(textValue)){

      var array = textarea.val().match(reg);

      textarea.closest('.js-limit').find('.character-count').html(280 - ((textarea.val().length - array[0].length) + 23) + ' <small>(min. 30 characters)</small>');
    }else {
      textarea.closest('.js-limit').find('.character-count').html(280 - textarea.val().length + ' <small>(min. 30 characters)</small>');
    }

  };

  // Expose public methods & properties
  return {
    init: init
  };

}());
