window.site = (window.site || {});

/**
 * Utilities for Google Maps.
 * @class Map
 * @static
 */
site.Map = (function Map() {

  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * jQuery elements.
   */
  var $maps = $('[data-map]');

  /**
   * Initializes the class.
   * @public
   */
  var init = function() {

    
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    // Load Google Maps script
    if ($maps.length) {

      var config = site.App.config('general');

      
      if (config.googleMaps && config.googleMaps !== '') {

        
        $('body').append($('<script src="https://maps.googleapis.com/maps/api/js?v=3&language=en&callback=site.Map.initMaps&key=' + config.googleMaps + '" ></script>'));

      }

    }

    // Return success
    return true;

  };

  /**
   * Initialize Google Maps instances.
   * @public
   */
  var initMaps = function($elements) {

    
    if (typeof $elements === 'undefined') {
      $elements = $('[data-map]');
    }

    $elements.each(function() {

      
      var $map = $(this);

      var address = false;
      var lat = false;
      var lng = false;

      // Check if address or coordinates are provided
      if ($map.data('map-address') && $map.data('map-address') !== '') {

        address = $map.data('map-address');

      } else if ($map.data('map-lat') && $map.data('map-lng')) {

        lat = parseFloat($map.data('map-lat')) || false;
        lng = parseFloat($map.data('map-lng')) || false;

      }

      // Map options and styles
      var options = {
        zoom: 14,
        minZoom: 1,
        draggable: site.App.config('device') === 'desktop',
        scrollwheel: false,
        mapTypeControl: false,
        panControl: false,
        rotateControl: false,
        scaleControl: false,
        zoomControl: false,
        streetViewControl: false,
        disableDefaultUI: true,
        disableDoubleClickZoom: false,
        styles: [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}]
      };

      // Geocode the address or use provided coordinates
      if (address) {

        var geocoder = new google.maps.Geocoder();

        geocoder.geocode({ address: address }, function(results, status) {

          
          if (status === google.maps.GeocoderStatus.OK) {

            
            var center = results[0].geometry.location;
            var map = new google.maps.Map($map[0], options);

            map.set('center', center);

            var marker = new google.maps.Marker({
              map: map,
              icon: '/assets/images/marker.svg',
              position: center
            });

            google.maps.event.addListener(marker, 'click', function() {
              window.open('https://www.google.ca/maps/dir//' + encodeURIComponent(address), '_blank');
            });

          }

        });

      } else if (lat && lng) {

        var center = new google.maps.LatLng(lat, lng);
        var map = new google.maps.Map($map[0], options);

        map.set('center', center);

        var marker = new google.maps.Marker({
          map: map,
          icon: '/assets/images/marker.svg',
          position: center
        });

        google.maps.event.addListener(marker, 'click', function() {
          window.open('https://www.google.ca/maps/dir//' + lat + ',' + lng, '_blank');
        });

      }

    });

  };

  // Expose public methods & properties
  return {
    init: init,
    initMaps: initMaps
  };

}());
