window.site = (window.site || {});

/**
 * Utilities for ProfileFilters component
 * @class ProfileFilters
 * @static
 */
site.Profile = (function Profile() {

  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;
  var $profilePost = $('.profile-post.cut-it');
  var $trigger = $('.profile-post .see-more');

  /**
   * Initializes the class.
   * @public
   */
  var init = function () {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    if ($profilePost.length) {
      if ($profilePost.height() > 500) {
        $profilePost.addClass('is-cut');
      }

      $trigger.click(function(e) {
        e.preventDefault();
        $profilePost.toggleClass('is-cut');

        if ($profilePost.hasClass('is-cut')) {
          $(this).text($(this).attr('data-active-text'));
        } else {
          $(this).text($(this).attr('data-inactive-text'));
        }
      });


    }
  };

  // Expose public methods & properties
  return {
    init: init
  };

}());
